import { makeStyles, Grid, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { socialData } from '../../../utils/socialData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    zIndex: 50,
    position: 'absolute',
  },
  bgContainer: {
    width: '319px',
    height: '65px',
    backgroundImage: `url(${socialData.pageInfo.bgMobile})`,
    backgroundSize: 'cover',
    backgroundColor: 'transparent',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'fixed',
    bottom: '0px',
  },
  container: {
    height: '60px',
    paddingTop: '6px',
  },
  socialMediaImage: {
    width: '50px',
    height: '50px',
  },
  gridContainer: {
    height: '100%',
    padding: '0px 30px',
  },
}));

const SocialMediaButtonsMobile = () => {
  const classes = useStyles();
  const [isHoverImage, setIsHoverImage] = useState(-1);

  const handleClickLogo = (url: string) => {
    setTimeout(() => {
      window.open(url, '_blank', 'noopener');
    }, 1000);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.bgContainer}>
          <div className={classes.container}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='flex-end'
              className={classes.gridContainer}>
              <Grid item>
                <img
                  onTouchStart={() => {
                    setIsHoverImage(1);
                    handleClickLogo(socialData.links.telegram);
                  }}
                  onTouchEnd={() => setIsHoverImage(-1)}
                  aria-hidden='true'
                  onFocus={() => void 0}
                  src={isHoverImage === 1 ? socialData.icons.telegramEnabled : socialData.icons.telegramDisabled}
                  alt='telegramDisabled'
                  className={classes.socialMediaImage}
                />
              </Grid>

              <Grid item>
                <img
                  onTouchStart={() => {
                    setIsHoverImage(2);
                    handleClickLogo(socialData.links.discord);
                  }}
                  onTouchEnd={() => setIsHoverImage(-1)}
                  aria-hidden='true'
                  onFocus={() => void 0}
                  src={isHoverImage === 2 ? socialData.icons.discordEnabled : socialData.icons.discordDisabled}
                  alt='discordDisabled'
                  className={classes.socialMediaImage}
                />
              </Grid>

              <Grid item>
                <img
                  onTouchStart={() => {
                    setIsHoverImage(3);
                    handleClickLogo(socialData.links.twitter);
                  }}
                  onTouchEnd={() => setIsHoverImage(-1)}
                  aria-hidden='true'
                  onFocus={() => void 0}
                  src={isHoverImage === 3 ? socialData.icons.twEnabled : socialData.icons.twDisabled}
                  alt='twDisabled'
                  className={classes.socialMediaImage}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaButtonsMobile;
