import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import background from '../../../../assets/images/header/bg.webp';
//import brillos from '../../../../assets/images/header/brillos.webp';
//import logo from '../../../../assets/images/header/logo.webp';
import logoGif from '../../../../assets/images/header/logoGif.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    margin: '0 auto',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    height: '100vh',
    zIndex: -2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    zIndex: 1,
    justifyContent: 'center',
    paddingTop: '70px',
  },
  shineImages: {
    width: '100%',
    height: 'auto',
    zIndex: 2,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '687px',
    maxHeight: '654px',
    position: 'relative',
    zIndex: 2,
  },
  logo: {
    width: '80%',
    height: 'auto',
  },
  logo2: {
    width: '60%',
    height: 'auto',
  },
}));

const logoGifImg = new Image();
logoGifImg.src = logoGif;

const LandingPageHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id='header'>
      <Grid className={`${classes.background}`}>
        <Grid className={classes.container}>
          <Grid container justifyContent='center' alignItems='center'>
            {/* <img
              className={`${classes.shineImages} animate__animated animate__heartBeat animate__slow animate__infinite`}
              src={brillos}
              alt='Logo Cats and Dogs'
            /> */}
            <div className={`${classes.logoContainer}`}>
              <img
                className={`${window.screen.height > 768 ? classes.logo : classes.logo2}`}
                src={logoGifImg.src}
                alt='Logo Cats and Dogs'
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPageHeader;
