import { AppBar, Box, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import logo from '../../../../assets/images/menu/logoBar.webp';
import menuBackground from '../../../../assets/images/menu/menuBgMobile.webp';
import miniBackground from '../../../../assets/images/menu/menuMiniBgMobile.webp';
import playNowButton from '../../../../assets/images/menu/playNow.webp';
import playNowButtonHover from '../../../../assets/images/menu/playNowHover.webp';
import toggleDisabled from '../../../../assets/images/menu/menuButton.webp';
import toggleEnabled from '../../../../assets/images/menu/menuButtonHover.webp';
import MenuDisplay from '../MenuMobile';

const playImg = new Image();
playImg.src = playNowButton;

const playHImg = new Image();
playHImg.src = playNowButtonHover;

const logoImg = new Image();
logoImg.src = logo;

const menuBackgroundImg = new Image();
menuBackgroundImg.src = menuBackground;

const miniBackgroundImg = new Image();
miniBackgroundImg.src = miniBackground;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    width: '100%',
    zIndex: 1900,
    left: '0px',
  },
  spanPlayNowContainer: {
    position: 'fixed',
    top: '0px',
    width: '100%',
  },
  playButtonContainer: {
    backgroundImage: `url(${playImg.src})`,
    backgroundSize: '100% 100%',
    backgroundColor: 'transparent',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '130px',
    height: 'auto',
    lineHeight: '80px',
    top: '16px',
    position: 'relative',
  },
  playButtonContainerHover: {
    backgroundImage: `url(${playHImg.src})`,
    backgroundSize: '100% 100%',
    backgroundColor: 'transparent',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '130px',
    height: 'auto',
    lineHeight: '80px',
    top: '16px',
    position: 'relative',
  },
  logoOneMin: {
    width: '80px',
    position: 'relative',
    left: '5px',
    top: '15px',
  },
  toggleButton: {
    width: '40px',
    position: 'relative',
    right: '26px',
    top: '20px',
    zIndex: 40,
  },
  containerMenuMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '82px',
    float: 'right',
    backgroundImage: `url(${menuBackgroundImg.src})`,
    backgroundSize: '100% 100%',
    boxShadow: '0 5px 20px 2px #00000080',
    backgroundColor: 'transparent',
  },
  appBarMini: {
    backgroundImage: `url(${miniBackgroundImg.src})`,
    backgroundSize: '100% 100%',
    height: '47px',
    boxShadow: '0 5px 20px 2px #00000080',
    zIndex: 10,
  },
  playNowLabel: {
    fontFamily: 'BlockBerthold',
    fontWeight: 400,
    textAlign: 'center',
    display: 'block',
    fontSize: '85%',
    color: 'white',
    userSelect: 'none',
    cursor: 'pointer',
  },
}));
interface props {
  handlePageChange: any;
  currentPage: number;
  handleExpandedMenu: any;
  expandedMenu: boolean;
  handleMiniMenu?: boolean;
}
const LandingPageMenuMobile = ({
  handlePageChange,
  currentPage,
  handleMiniMenu,
  handleExpandedMenu,
  expandedMenu,
}: props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [playNowHover, setPlayNowHover] = useState(false);

  function openMenu() {
    setMenuOpen(!menuOpen);
  }

  const openGame = () => {
    setTimeout(() => {
      window.open('https://play.catsanddogs.io/', '_blank', 'noopener');
    }, 1000);
  };

  return (
    <div className={classes.root}>
      <MenuDisplay
        menuType='landpage-consumer'
        onClose={openMenu}
        open={menuOpen}
        handlePageChange={handlePageChange}
        currentPage={currentPage}></MenuDisplay>
      {handleMiniMenu ? (
        <AppBar position='static' className={classes.appBarMini} />
      ) : (
        <AppBar>
          <div className={classes.containerMenuMobile}>
            <span onClick={() => handlePageChange(0)} onFocus={() => void 0} aria-hidden='true'>
              <img className={classes.logoOneMin} src={logoImg.src} alt='Logo Cats and Dogs' />
            </span>
            <span onClick={openMenu} aria-hidden='true'>
              <img
                className={classes.toggleButton}
                src={menuOpen ? toggleEnabled : toggleDisabled}
                alt='Logo Cats and Dogs'
              />
            </span>
          </div>
          <span className={classes.spanPlayNowContainer}>
            <Box
              className={playNowHover ? classes.playButtonContainerHover : classes.playButtonContainer}
              onTouchStart={(e) => setPlayNowHover(true)}
              onTouchEnd={(e) => {
                setPlayNowHover(false);
                openGame();
              }}>
              <span className={classes.playNowLabel} aria-hidden='true'>
                PLAY NOW
              </span>
            </Box>
          </span>
        </AppBar>
      )}
    </div>
  );
};

export default LandingPageMenuMobile;
