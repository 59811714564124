import React, { useState } from 'react';
import { AppBar, Box, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { Link } from 'react-scroll';
import logo from '../../../../assets/images/menu/logoBar.webp';
import menuBackground from '../../../../assets/images/menu/menuBackground.webp';
import miniBackground from '../../../../assets/images/menu/menuMiniBackground.webp';
import playNowButton from '../../../../assets/images/menu/playNow.webp';
import playNowButtonHover from '../../../../assets/images/menu/playNowHover.webp';
import { sectionList } from '../../../../utils/sectionMenu';
import MenuDisplay from '../Menu';

const playImg = new Image();
playImg.src = playNowButton;

const playHImg = new Image();
playHImg.src = playNowButtonHover;

const logoImg = new Image();
logoImg.src = logo;

const menuBackgroundImg = new Image();
menuBackgroundImg.src = menuBackground;

const miniBackgroundImg = new Image();
miniBackgroundImg.src = miniBackground;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    width: '100%',
    zIndex: 1900,
  },
  appBar: {
    backgroundImage: `url(${menuBackgroundImg.src})`,
    backgroundSize: '100% 100%',
    height: '79px',
    backgroundColor: 'transparent',
    boxShadow: '0 5px 20px 2px #00000080',
    [theme.breakpoints.up('xl')]: {
      height: '89px',
    },
  },
  appBarMini: {
    backgroundImage: `url(${miniBackgroundImg.src})`,
    backgroundSize: '100% 100%',
    height: '54px',
    boxShadow: '0 5px 20px 2px #00000080',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('lg')]: {
      height: '60px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '74px',
    },
  },
  expandButton: {
    backgroundSize: '100% 100%',
    height: '52px',
    width: '62px',
    top: '-6px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    display: 'block',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  playButtonContainer: {
    cursor: 'pointer',
    backgroundImage: `url(${playImg.src})`,
    backgroundSize: '100% 100%',
    marginRight: '72px',
    marginTop: '68px',
    width: '222px',
    height: '127px',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('xl')]: {
      width: '245px',
      height: '132px',
    },
  },
  playButtonContainerHover: {
    cursor: 'pointer',
    backgroundImage: `url(${playHImg.src})`,
    backgroundSize: '100% 100%',
    marginRight: '72px',
    marginTop: '68px',
    width: '222px',
    height: '127px',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('xl')]: {
      width: '245px',
      height: '132px',
    },
  },
  toolbar: {
    padding: '0px',
    maxHeight: '79px',
    [theme.breakpoints.up('xl')]: {},
  },
  logoLeft: {},
  logoOne: {
    width: '90px',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      width: '90px',
      marginLeft: '30%',
      top: '70px',
    },
    [theme.breakpoints.up('xl')]: {
      top: '75px',
    },
  },
  logoOneMobile: {},
  logoOneMin: {
    width: '90px',
    position: 'relative',
    padding: '8px 0px 0px 40px',
  },
  containerMenuDesktop: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',
  },
  containerMenuMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonText: {
    fontFamily: 'BloggerSans',
    fontWeight: 400,
    color: 'white',
    padding: '90px 15px',
    fontSize: '14pt',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      fontFamily: 'BloggerSansBold',
      fontWeight: 700,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '14.5pt',
      padding: '95px 40px 80px 40px',
    },
  },
  buttonLogo: {
    cursor: 'pointer',
    padding: '0px 60px 0px 15px',
  },
  spacer: {
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      padding: '10px',
    },
  },
  imagePlayNow: {
    position: 'relative',
  },
  buttonIconMenu: {
    marginRight: '30px',
  },
  menuItemActive: {
    '& span': {
      fontFamily: 'BloggerSansBold',
      fontWeight: 700,
      color: '#23D9FF',
      textDecoration: 'underline #23D9FF 3px solid',
    },
  },
  menuItemActiveIndividual: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    color: '#23D9FF',
  },
  linksContainer: {
    display: 'inline-flex',
    '& a': {
      position: 'relative',
    },
  },
  playNowLabel: {
    fontFamily: 'BlockBerthold',
    fontWeight: 400,
    textAlign: 'center',
    position: 'relative',
    display: 'block',
    fontSize: '18pt',
    marginTop: '49px',
    userSelect: 'none',
    cursor: 'pointer',
    [theme.breakpoints.up('xl')]: {
      fontSize: '20pt',
      marginTop: '49px',
    },
  },
  desktopMenu: {
    [theme.breakpoints.up(1380)]: {
      display: 'inline',
    },
    [theme.breakpoints.down(1380)]: {
      display: 'none',
    },
  },
  mobileMenu: {
    [theme.breakpoints.down(1379)]: {
      display: 'inline',
      zIndex: 10,
    },
    [theme.breakpoints.up(1379)]: {
      display: 'none',
    },
  },
}));

interface props {
  handlePageChange: any;
  currentPage: number;
  handleExpandedMenu: any;
  expandedMenu: boolean;
}
const LandingPageMenu = ({ handlePageChange, currentPage, handleExpandedMenu, expandedMenu }: props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isHoveringMenuItem, setIsHoveringMenuItem] = useState('hero');
  const [playNowHover, setPlayNowHover] = useState(false);
  function openMenu() {
    setMenuOpen(!menuOpen);
  }

  const openGame = () => {
    window.open('https://play.catsanddogs.io/', '_blank', 'noopener');
  };

  return (
    <div className={classes.root}>
      <MenuDisplay
        menuType='landpage-consumer'
        onClose={openMenu}
        open={menuOpen}
        handlePageChange={handlePageChange}
        currentPage={currentPage}></MenuDisplay>
      <>
        <div className={classes.mobileMenu}>
          <AppBar position='static' className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <div className={classes.containerMenuMobile}>
                <Link activeClass='active' to='hero' spy={true} smooth={true} offset={0} duration={500}>
                  <span onClick={() => handlePageChange(0)} onFocus={() => void 0} aria-hidden='true'>
                    <img className={classes.logoOneMin} src={logoImg.src} alt='Logo Cats and Dogs' />
                  </span>
                </Link>
                <IconButton
                  onClick={openMenu}
                  disableRipple
                  className='button-icon-content mt-0 mb-0 pr-0 pt-0 pb-0'
                  edge='start'
                  color='inherit'
                  aria-label='menu'>
                  <Menu className={`${classes.buttonIconMenu} button-icon`} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>

        <div className={classes.desktopMenu}>
          <AppBar
            position='static'
            className={currentPage === 0 || expandedMenu === true ? classes.appBar : classes.appBarMini}>
            <Toolbar className={classes.toolbar}>
              {(currentPage === 0 || expandedMenu === true) && (
                <div
                  className={classes.containerMenuDesktop}
                  onMouseLeave={(e) => handleExpandedMenu(false)}
                  onFocus={() => void 0}
                  aria-hidden='true'>
                  <div className={classes.linksContainer}>
                    <span
                      onClick={() => handlePageChange(0)}
                      onFocus={() => void 0}
                      aria-hidden='true'
                      className={classes.buttonLogo}>
                      <img className={classes.logoOne} src={logoImg.src} alt='Logo Cats and Dogs' />
                    </span>
                    {sectionList.map((section) => (
                      <span
                        key={section.key}
                        onFocus={() => void 0}
                        aria-hidden='true'
                        onMouseOver={(e) => setIsHoveringMenuItem(section.key)}
                        onMouseLeave={(e) => setIsHoveringMenuItem('')}
                        onClick={() => handlePageChange(section.page)}
                        className={`${classes.buttonText} ${
                          isHoveringMenuItem === section.key || currentPage === section.page
                            ? classes.menuItemActiveIndividual
                            : ''
                        }`}>
                        {section.value}
                      </span>
                    ))}
                  </div>
                  <Box
                    className={playNowHover ? classes.playButtonContainerHover : classes.playButtonContainer}
                    onMouseOver={(e) => setPlayNowHover(true)}
                    onMouseLeave={(e) => setPlayNowHover(false)}
                    onClick={() => openGame()}>
                    <span className={classes.playNowLabel} aria-hidden='true'>
                      PLAY NOW
                    </span>
                  </Box>
                </div>
              )}
              {currentPage !== 0 && !expandedMenu && (
                <div
                  onMouseOver={(e) => handleExpandedMenu(true)}
                  onFocus={() => void 0}
                  aria-hidden='true'
                  className={classes.expandButton}></div>
              )}
            </Toolbar>
          </AppBar>
        </div>
      </>
    </div>
  );
};

export default LandingPageMenu;
