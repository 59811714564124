import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import background from '../../../../assets/images/header/bgMobile.webp';
//import brillos from '../../../../assets/images/header/brillos.webp';
//import logo from '../../../../assets/images/header/logo.webp';
import logoGif from '../../../../assets/images/header/logoGif.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    zIndex: -2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shineImages: {
    width: '600px',
    height: 'auto',
    zIndex: 2,
  },
  logo: {
    width: '90%',
    height: 'auto',
    maxWidth: '687px',
    maxHeight: '654px',
    zIndex: 2,
  },
}));

const logoGifImg = new Image();
logoGifImg.src = logoGif;

const LandingPageHeaderMobile = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id='header'>
      <Grid className={classes.background}>
        <Grid container justifyContent='center' alignItems='center'>
          {/* <img
              className={`${classes.shineImages} animate__animated animate__heartBeat animate__slow animate__infinite`}
              src={brillos}
              alt='Logo Cats and Dogs'
            /> */}
          <img className={`${classes.logo}`} src={logoGifImg.src} alt='Logo Cats and Dogs' />
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPageHeaderMobile;
