export const sectionsGameView = [
  {
    name: 'GameView1',
    src: 'https://www.youtube.com/embed/-DwwULON6fA',
  },
  {
    name: 'GameView2',
    src: 'https://player.vimeo.com/progressive_redirect/playback/676386554/rendition/1080p?loc=external&signature=302b242c65c16a056801ebbd5b221ce5a3b5b41815050d8582d66e4c38512db9',
  },
];
