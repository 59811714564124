import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from '../../../../hooks/IntersectionObserver.hook';
import background from '../../../../assets/images/partners/bg.webp';
import header from '../../../../assets/images/partners/header.webp';
import { sectionsPartners } from '../../../../utils/sectionPartnersData';
//import CarouselDesktopNavigator from './CarouselDesktopNavigator';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    margin: '0 auto',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    height: '100vh',
    zIndex: -2,
  },
  logosContainer: {
    animation: 'fadeoutfadeinTeam 1s linear',
    maxWidth: '100%',
    justifyContent: 'space-evenly',
    margin: 'auto',
    width: '80%',
    marginTop: '30vh',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  logoImage: {
    width: '100%',
  },
  item: {
    [theme.breakpoints.only('sm')]: {
      margin: '15px',
    },
    [theme.breakpoints.only('md')]: {
      margin: '10px',
    },
    [theme.breakpoints.only('lg')]: {
      margin: '0 10px',
    },
    [theme.breakpoints.only('xl')]: {
      margin: '0 20px',
    },
  },
  animationContainer: {
    animation: 'fadeoutfadeinTeam 1s linear',
  },
  imageAnimation: {
    opacity: 0,
  },
  textAnimation: {
    opacity: 0,
    animation: 'jackInTheBox 2s both 1',
    position: 'absolute',
    top: '90px',
    [theme.breakpoints.only('lg')]: {
      top: '80px',
    },
    [theme.breakpoints.only('xl')]: {
      top: '100px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerImage: {
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '400px',
    },
  },
  logoHover: {
    cursor: 'pointer',
    transform: 'scale(1.2)',
    backgroundColor: '#68030F10',
    borderRadius: '12px',
  },
}));

const LandingPagePartners = () => {
  const classes = useStyles();
  //const [activeIndex, setActiveIndex] = useState(0);
  const [classNameSet, setClassName] = useState('');
  const [isHoverImage, setIsHoverImage] = useState(-1);
  const [hoverOn, setHoverOn] = useState('');
  const refSection = useRef(null);

  useEffect(() => {
    setClassName(classes.animationContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classNameSet]);

  // const updateIndex = (newIndex: number) => {
  //   setClassName(classes.imageAnimation);
  //   if (newIndex >= 0 && newIndex < sectionsPartners.length) {
  //     setActiveIndex(newIndex);
  //     setTimeout(() => {
  //       setClassName(classes.animationContainer);
  //     }, 500);
  //   } else if (newIndex >= sectionsPartners.length) {
  //     setActiveIndex(0);
  //   }
  // };

  const isSectionVisible = useIntersectionObserver(refSection, { threshold: 0 }, true);

  const handleClickLogo = (url: string) => {
    window.open(url, '_blank', 'noopener');
  };

  return (
    <>
      <div className={classes.root} id='partners'>
        <Grid container direction='column' alignItems='center' justifyContent='center' className={classes.background}>
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            className={`${isSectionVisible ? classes.textAnimation : ''} ${classNameSet}`}
            ref={refSection}>
            {isSectionVisible ? (
              <Grid item xs={12} className={classes.title}>
                <img src={header} alt='imageheader' className={classes.headerImage} />
              </Grid>
            ) : null}
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-evenly'
            className={`${classes.logosContainer} ${classNameSet}`}>
            {sectionsPartners.logos.map((logo, i) => (
              <Grid
                item
                xs={1}
                container
                alignItems='center'
                justifyContent='center'
                key={`${logo.url}`}
                className={`${classNameSet} ${classes.item}`}>
                <Grid className={classes.cardContainer}>
                  <img
                    aria-hidden='true'
                    src={hoverOn === `logo${i}` ? logo.srcColor : logo.srcGray}
                    alt='imageteam'
                    className={isHoverImage === i ? `${classes.logoImage} ${classes.logoHover}` : classes.logoImage}
                    onClick={() => handleClickLogo(logo.url)}
                    onMouseEnter={() => {
                      setHoverOn(`logo${i}`);
                      setIsHoverImage(i);
                    }}
                    onMouseLeave={() => {
                      setHoverOn('');
                      setIsHoverImage(-1);
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          {/* <CarouselDesktopNavigator data={sectionsPartners} activeIndex={activeIndex} updateIndex={updateIndex} /> */}
        </Grid>
      </div>
    </>
  );
};

export default LandingPagePartners;
