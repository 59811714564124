import bg from '../assets/images/footer/bg.webp';
import bgMobile from '../assets/images/footer/bgMobile.webp';
import telegramDisabled from '../assets/images/social/telegramButton.webp';
import logo from '../assets/images/common/appLogo.webp';
import telegramEnabled from '../assets/images/social/telegramHoverButton.webp';
import discordDisabled from '../assets/images/social/discordButton.webp';
import discordEnabled from '../assets/images/social/discordHoverButton.webp';
import twDisabled from '../assets/images/social/twButton.webp';
import twEnabled from '../assets/images/social/twHoverButton.webp';

const bgImg = new Image();
bgImg.src = bg;
const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const logoImg = new Image();
logoImg.src = logo;

const tgdImg = new Image();
tgdImg.src = telegramDisabled;
const tgeImg = new Image();
tgeImg.src = telegramEnabled;

const ddImg = new Image();
ddImg.src = discordDisabled;
const deImg = new Image();
deImg.src = discordEnabled;

const tweImg = new Image();
tweImg.src = twEnabled;
const twdImg = new Image();
twdImg.src = twDisabled;

export const contactUsSection = {
  pageInfo: {
    title: 'Contact us',
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
    logo: logoImg.src,
  },
  pages: [
    {
      name: 'Welcome!',
      toPage: 'welcome',
      pageId: 1,
      toPageMobile: 'welcomeMobile',
      pageMobileId: 1,
    },
    {
      name: 'Game View',
      toPage: 'gameView',
      pageId: 2,
      toPageMobile: 'gameViewMobile',
      pageMobileId: 2,
    },
    {
      name: 'Our Goal',
      toPage: 'ourGoal',
      pageId: 3,
      toPageMobile: 'ourGoalMobile',
      pageMobileId: 3,
    },
    {
      name: 'Let´s Move!',
      toPage: 'letsMove',
      pageId: 4,
      toPageMobile: 'letsMoveMobile',
      pageMobileId: 4,
    },
    {
      name: 'Team & ADV',
      toPage: 'team',
      pageId: 6,
      toPageMobile: 'teamMobile',
      pageMobileId: 6,
    },
    {
      name: 'Documents',
      toPage: 'docs',
      pageId: 9,
      toPageMobile: 'docsMobile',
      pageMobileId: 9,
    },
  ],
  socialIcons: [
    {
      name: 'telegram',
      url: 'https://t.me/CatsDogsOfficialChat',
      imgHover: telegramEnabled,
      img: telegramDisabled,
      setImg: 1,
    },
    {
      name: 'discord',
      url: 'https://discord.gg/catsanddogs',
      imgHover: discordEnabled,
      img: discordDisabled,
      setImg: 2,
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/CDOfficialNFTs',
      imgHover: twEnabled,
      img: twDisabled,
      setImg: 3,
    },
  ],
};
