import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import CarouselComponent from './Carousel';
import { sectionRoadMap } from '../../../../utils/sectionRoadMapData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    backgroundImage: `url(${sectionRoadMap.pageInfo.bgMobile})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    zIndex: -2,
  },
  contentContainer: {
    padding: '12vh 10%',
  },
  image: {
    heigth: 'auto',
    width: '80%',
  },
  headerImage: {
    width: '90%',
  },
  carousel: {
    maxHeight: '75%',
    marginTop: '-40px',
  },
}));

const LandingPageRoadMapMobile = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root} id='roadMap'>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={`${classes.background} ${classes.contentContainer}`}>
          <Grid container direction='column' alignItems='center' justifyContent='center'>
            <img
              aria-hidden='true'
              src={sectionRoadMap.pageInfo.titleImg}
              alt='imageheader'
              className={classes.headerImage}
            />
          </Grid>
          <CarouselComponent className={classes.carousel}>
            {sectionRoadMap.cards.map((section) => (
              <Grid item key={section.name}>
                <img aria-hidden='true' src={section.image} alt='imageheader' className={classes.image} />
              </Grid>
            ))}
          </CarouselComponent>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageRoadMapMobile;
