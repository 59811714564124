/* eslint-disable jsx-a11y/media-has-caption */
import { makeStyles, Theme, Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import background from '../../../../assets/images/gameView/mobileBg.webp';
import border from '../../../../assets/images/gameView/border.webp';
import { sectionsGameView } from '../../../../utils/sectionGameViewData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    background: 'black',
  },

  gridContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    '&>iframe': {
      width: '100%',
    },
  },
  videoVimeo: {
    borderImage: `url(${border}) 30 20 stretch`,
    borderImageSlice: '70',
    borderWidth: '16px',
    borderStyle: 'solid',
    width: '95%',
    height: '30vh',
    marginLeft: '0px',
  },
  assetContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    justifySelf: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  borderContainer: {
    width: '100%',
    height: 'fit-content',
    position: 'relative',
    marginBottom: '30px',
    '&>img': {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
  },
}));

const LandingPageGameViewMobile = () => {
  const classes = useStyles();
  const videoRef1 = useRef(null);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.assetContainer}>
          <Grid container direction='row' alignItems='center' justifyContent='center' className={classes.gridContainer}>
            {/* <video width='320' height='240' controls className={classes.videoVimeo}>
              <source type='video/mp4' src={sectionsGameView[0].src} />
            </video> */}
            <iframe
              width='100%'
              height='100%'
              ref={videoRef1}
              className={classes.videoVimeo}
              src={sectionsGameView[0].src}
              frameBorder='0'
              allow='fullscreen; accelerometer; autoplay; encrypted-media; picture-in-picture'
              allowFullScreen
              title='video'></iframe>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LandingPageGameViewMobile;
