import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { sectionDocs } from '../../../../utils/sectionDocsData';
import CarouselComponent from '../landingPageMobile/Carousel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  background: {
    backgroundImage: `url(${sectionDocs.pageInfo.bgMobile})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    zIndex: -2,
  },
  contentContainer: {
    padding: '12vh 10%',
  },
  headerImage: {
    width: '90%',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
  },
  cardContent: {
    zIndex: 9,
    justifyContent: 'start',
    marginLeft: '10px',
    padding: '0px 50px',
    marginTop: '10vh',
    height: '40vh',
  },
  imageDoc: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
  },
  iconImage: {
    marginTop: '4vh',
    width: '12vh',
  },
  cardText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    color: '#68030F',
    lineHeight: '20px',
    fontSize: '18px',
    marginTop: '2vh',
    padding: '0px 10px',
  },
  buttonImage: {
    marginTop: '2vh',
    width: '15vh',
  },
  carousel: {
    maxHeight: '65%',
    marginTop: '-30px',
  },
}));

const LandingPageDocsMobile = () => {
  const classes = useStyles();
  const [hoverOn, setHoverOn] = useState('');

  const handleClick = (url: string) => {
    setTimeout(() => {
      setHoverOn('');
      window.open(url, '_blank', 'noopener');
    }, 1000);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='space-between'
        className={`${classes.background} ${classes.contentContainer}`}>
        <Grid container direction='column' alignItems='center' justifyContent='center'>
          <img
            aria-hidden='true'
            src={sectionDocs.pageInfo.titleImg}
            alt='imageheader'
            className={classes.headerImage}
          />
        </Grid>
        <CarouselComponent className={classes.carousel}>
          {sectionDocs.cards.map((doc) => (
            <Grid className={classes.cardContainer} key={doc.name}>
              <img aria-hidden='true' src={doc.image} alt={doc.name} className={classes.imageDoc} />
              <Grid container direction='column' alignItems='center' className={classes.cardContent}>
                <img src={doc.icon} alt={doc.name} className={classes.iconImage} aria-hidden='true' />
                <span className={classes.cardText}>{doc.text}</span>
                <img
                  src={hoverOn === doc.name ? sectionDocs.pageInfo.buttonHoverImg : sectionDocs.pageInfo.buttonImg}
                  alt={doc.name}
                  className={classes.buttonImage}
                  onClick={() => handleClick(doc.url)}
                  aria-hidden='true'
                  onMouseEnter={() => setHoverOn(`${doc.name}`)}
                  onMouseLeave={() => setHoverOn('')}
                />
              </Grid>
            </Grid>
          ))}
        </CarouselComponent>
      </Grid>
    </div>
  );
};

export default LandingPageDocsMobile;
