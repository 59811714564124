import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
//import CarouselComponent from '../landingPageMobile/Carousel';
import background from '../../../../assets/images/partners/bgMobile.webp';
import header from '../../../../assets/images/partners/header.webp';
import { Logo, sectionsPartners } from '../../../../utils/sectionPartnersData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    zIndex: -2,
  },
  contentContainer: {
    padding: '12vh 10%',
  },
  images: {
    transition: 'all .5s ease-in-out',
    padding: '0px',
    maxHeight: '8vh',
  },
  textAnimation: {
    animation: 'fadeIn 2s both 1',
  },
  hoverEffect: {
    transform: 'scale(1.5)',
  },
  title: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  headerImage: {
    width: '90%',
  },
  logoHover: {
    cursor: 'pointer',
    transform: 'scale(1.2)',
    backgroundColor: '#00000010',
    borderRadius: '12px',
  },
  logosContainer: {
    justifyContent: 'space-evenly',
    margin: 'auto',
    width: '100%',
    height: '80%',
  },
  logoImage: {
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    width: '100%',
  },
  carousel: {
    maxHeight: '80%',
    marginTop: '-20px',
  },
}));

const LandingPagePartnersMobile = () => {
  const classes = useStyles();
  const [isHoverImage, setIsHoverImage] = useState(-1);
  const [hoverOn, setHoverOn] = useState('');

  const handleClickLogo = (url: string) => {
    setTimeout(() => {
      setHoverOn('');
      setIsHoverImage(-1);
      window.open(url, '_blank', 'noopener');
    }, 1000);
  };

  const PartnersGroup = (props: { data: Logo[] }) => {
    return (
      <Grid container spacing={0} alignItems='center' justifyContent='space-evenly' className={classes.logosContainer}>
        {props.data.map((logo, i) => (
          <Grid item xs={2} container alignItems='center' justifyContent='center' key={`${logo.url}`}>
            <Grid key={`${logo.url}`} className={classes.cardContainer}>
              <img
                aria-hidden='true'
                src={hoverOn === `logo${i}` ? logo.srcColor : logo.srcGray}
                alt='imageteam'
                className={isHoverImage === i ? `${classes.logoImage} ${classes.logoHover}` : classes.logoImage}
                onTouchStart={() => {
                  setHoverOn(`logo${i}`);
                  setIsHoverImage(i);
                  handleClickLogo(logo.url);
                }}
                onTouchEnd={() => {
                  setHoverOn('');
                  setIsHoverImage(-1);
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <div className={classes.root} id='partners'>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={`${classes.background} ${classes.contentContainer}`}>
          <Grid container direction='column' alignItems='center' justifyContent='center'>
            <img src={header} alt='imageheader' className={classes.headerImage} />
          </Grid>
          <PartnersGroup data={sectionsPartners.logos} />
          {/* <CarouselComponent className={classes.carousel}>
            <PartnersGroup data={sectionsPartners[0].logos} />
            <PartnersGroup data={sectionsPartners[1].logos} />
            <PartnersGroup data={sectionsPartners[2].logos} />
            <PartnersGroup data={sectionsPartners[3].logos} />
          </CarouselComponent> */}
        </Grid>
      </div>
    </>
  );
};

export default LandingPagePartnersMobile;
