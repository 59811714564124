import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useRef } from 'react';
import { sectionsWelcome } from '../../../../utils/sectionWelcomeData';
import background from '../../../../assets/images/welcome/mobileBg.webp';
import CarouselComponent from './Carousel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    zIndex: -2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    zIndex: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    disply: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    fontSize: '15pt',
    padding: '0 10%',
    display: 'block',
  },
  title: {
    fontFamily: 'BlockBerthold',
    fontWeight: 400,
    display: 'block',
    fontSize: '30pt',
    textAlign: 'center',
    padding: '0 10%',
  },
  textContainer: {
    paddingTop: '32px',
  },
  carousel: {
    height: '75vh',
  },
  cat: {
    bottom: '15%',
    left: '1%',
    width: '40%',
    position: 'absolute',
    zIndex: 1,
  },
  dog: {
    bottom: '15%',
    right: '1%',
    width: '57%',
    position: 'absolute',
    zIndex: 1,
  },
  dogGif: {
    bottom: '15%',
    left: '20%',
    width: '65%',
    position: 'absolute',
    zIndex: 1,
  },
}));

const LandingPageWelcomeMobile = () => {
  const classes = useStyles();

  const refSection = useRef(null);

  return (
    <div className={classes.root} id='welcome'>
      {/* <img src={sectionsWelcome.pageInfo.cat} className={classes.cat} alt='cat' /> */}
      <img src={sectionsWelcome.pageInfo.dogGif} className={classes.dogGif} alt='dog' />
      <Grid className={classes.background}>
        <Grid direction='column' container alignItems='center' className={classes.container}>
          <CarouselComponent className={classes.carousel}>
            {sectionsWelcome.cards.map((section, index) => (
              <Grid className={classes.content} key={index}>
                <Grid item xs={12}>
                  <span ref={refSection} className={`${classes.title}`}>
                    {section.title}
                  </span>
                </Grid>
                <Grid item xs={12} className={classes.textContainer}>
                  <div className={classes.contentText}>{section.text}</div>
                </Grid>
              </Grid>
            ))}
          </CarouselComponent>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPageWelcomeMobile;
