import bg from '../assets/images/ourGoal/bg.webp';
import bg2 from '../assets/images/ourGoal/bg2.webp';
import bgMobile from '../assets/images/ourGoal/bgMobile.webp';
import bgShadow from '../assets/images/ourGoal/bgShadow.webp';
import goal1char from '../assets/images/ourGoal/dog.webp';
import goal1header from '../assets/images/ourGoal/icon_1.webp';
import goal2char from '../assets/images/ourGoal/cats.webp';
import goal2header from '../assets/images/ourGoal/icon_2.webp';
import goal3char from '../assets/images/ourGoal/dogs.webp';
import goal3header from '../assets/images/ourGoal/icon_3.webp';
import star1 from '../assets/images/ourGoal/star_1.webp';
import star2 from '../assets/images/ourGoal/star_2.webp';
import star3 from '../assets/images/ourGoal/star_3.webp';
import slider1 from '../assets/images/ourGoal/slider_1.gif';
import slider2 from '../assets/images/ourGoal/slider_2.gif';
import slider3 from '../assets/images/ourGoal/slider_3.gif';
import goal1charGif from '../assets/images/ourGoal/happy-idle.gif';

const bgImg = new Image();
bgImg.src = bg;
const bg2Img = new Image();
bg2Img.src = bg2;
const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;
const bgShadowImg = new Image();
bgShadowImg.src = bgShadow;

const image1Char = new Image();
const image1Header = new Image();
const image2Char = new Image();
const image2Header = new Image();
const image3Char = new Image();
const image3Header = new Image();
const imageStar1 = new Image();
const imageStar2 = new Image();
const imageStar3 = new Image();

image1Header.src = goal1header;
image1Char.src = goal1char;
image2Header.src = goal2header;
image2Char.src = goal2char;
image3Header.src = goal3header;
image3Char.src = goal3char;

imageStar1.src = star1;
imageStar2.src = star2;
imageStar3.src = star3;

const image1CharGif = new Image();
image1CharGif.src = goal1charGif;
const slider1Gif = new Image();
slider1Gif.src = slider1;
const slider2Gif = new Image();
slider2Gif.src = slider2;
const slider3Gif = new Image();
slider3Gif.src = slider3;

export const sectionsGoals = {
  pageInfo: {
    bg: bgImg.src,
    bg2: bg2Img.src,
    bgMobile: bgMobileImg.src,
    bgShadow: bgShadowImg.src,
  },
  sections: [
    {
      name: 'goal1',
      title: 'Universal Crypto Games',
      text: [
        `Cats & Dogs is accessible for all audiences who love animals. 
      Enjoy the combination of exciting Mini-Games, 
      Pets and a wonderful Metaverse. `,
        `Wholeful Adventures Await!`,
      ],
      imageHeader: image1Header.src,
      imageChar1: image1Char.src,
      imageChar1Gif: image1CharGif.src,
      slider1: slider1Gif.src,
      imageStar1: imageStar1.src,
      imageStar2: imageStar2.src,
      imageStar3: imageStar3.src,
    },
    {
      name: 'goal2',
      title: 'More than NFTs!',
      text: [
        `Your Pets are more than just assets: they are your friends!
      We want to forge a new generation of games that are as democratic as they are wholeful.`,
        `We will revolutionize the relation between Players and Games!`,
      ],
      imageHeader: image2Header.src,
      imageChar2: image2Char.src,
      slider2: slider2Gif.src,
      imageStar1: imageStar1.src,
      imageStar2: imageStar2.src,
      imageStar3: imageStar3.src,
    },
    {
      name: 'goal3',
      title: 'Made For The Long-Run',
      text: [
        `We are here to stay. We share your vision for a sustainable experience that is as entertaining as is secure.`,
        `We design with the idea that everyone may play and earn together!`,
      ],
      imageHeader: image3Header.src,
      imageChar3: image3Char.src,
      slider3: slider3Gif.src,
      imageStar1: imageStar1.src,
      imageStar2: imageStar2.src,
      imageStar3: imageStar3.src,
    },
  ],
};
