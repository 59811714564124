import { makeStyles, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { socialData } from '../../../utils/socialData';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70px',
    height: '280px',
    position: 'fixed',
    float: 'right',
    right: '0px',
    top: '33vh',
    backgroundImage: `url(${socialData.pageInfo.bgWeb})`,
    zIndex: 99999,
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '50px',
      height: '200px',
    },
  },
  container: {
    width: '59px',
    float: 'right',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '45px',
    },
  },
  socialMediaImage: {
    width: '54px',
    height: '54px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px',
    },
  },
  gridContainer: {
    height: '100%',
    padding: '30px 0px 30px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px 10px 0px',
    },
  },
}));

const SocialMediaButtons = () => {
  const classes = useStyles();
  const [isHoverImage, setIsHoverImage] = useState(-1);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='space-around'
            className={classes.gridContainer}>
            <Grid item>
              <a href={socialData.links.telegram} target='_blank' rel='noreferrer'>
                <img
                  onMouseOver={(e) => setIsHoverImage(1)}
                  onMouseLeave={(e) => setIsHoverImage(-1)}
                  aria-hidden='true'
                  onFocus={() => void 0}
                  src={isHoverImage === 1 ? socialData.icons.telegramEnabled : socialData.icons.telegramDisabled}
                  alt='telegramDisabled'
                  className={classes.socialMediaImage}
                />
              </a>
            </Grid>

            <Grid item>
              <a href={socialData.links.discord} target='_blank' rel='noreferrer'>
                <img
                  onMouseOver={(e) => setIsHoverImage(2)}
                  onMouseLeave={(e) => setIsHoverImage(-1)}
                  aria-hidden='true'
                  onFocus={() => void 0}
                  src={isHoverImage === 2 ? socialData.icons.discordEnabled : socialData.icons.discordDisabled}
                  alt='discordDisabled'
                  className={classes.socialMediaImage}
                />
              </a>
            </Grid>

            <Grid item>
              <a href={socialData.links.twitter} target='_blank' rel='noreferrer'>
                <img
                  onMouseOver={(e) => setIsHoverImage(3)}
                  onMouseLeave={(e) => setIsHoverImage(-1)}
                  aria-hidden='true'
                  onFocus={() => void 0}
                  src={isHoverImage === 3 ? socialData.icons.twEnabled : socialData.icons.twDisabled}
                  alt='twDisabled'
                  className={classes.socialMediaImage}
                />
              </a>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default SocialMediaButtons;
