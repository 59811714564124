import bg from '../assets/images/nfts/bg.webp';
import bgMobile from '../assets/images/nfts/bgMobile.webp';
import header from '../assets/images/nfts/header.webp';
import golden1 from '../assets/images/nfts/golden-01.webp';
import golden2 from '../assets/images/nfts/golden-02.webp';
import goldenText from '../assets/images/nfts/golden-text.webp';
import nfts1 from '../assets/images/nfts/nfts/01.webp';
import nfts2 from '../assets/images/nfts/nfts/02.webp';
import nfts3 from '../assets/images/nfts/nfts/03.webp';
import nfts4 from '../assets/images/nfts/nfts/04.webp';
import nfts5 from '../assets/images/nfts/nfts/05.webp';
import nfts6 from '../assets/images/nfts/nfts/06.webp';
import soon from '../assets/images/nfts/soon.webp';

const headerImg = new Image();
headerImg.src = header;

const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const bgImg = new Image();
bgImg.src = bg;

const soonImg = new Image();
soonImg.src = soon;

const image1Nft = new Image();
image1Nft.src = nfts1;

const image2Nft = new Image();
image2Nft.src = nfts2;

const image3Nft = new Image();
image3Nft.src = nfts3;

const image4Nft = new Image();
image4Nft.src = nfts4;

const image5Nft = new Image();
image5Nft.src = nfts5;

const image6Nft = new Image();
image6Nft.src = nfts6;

const imageGolden1 = new Image();
imageGolden1.src = golden1;

const imageGolden2 = new Image();
imageGolden2.src = golden2;

const imageGoldenText = new Image();
imageGoldenText.src = goldenText;

export const sectionNfts = {
  pageInfo: {
    title: 'The Best Pets!',
    text: 'Adopt a Pet and start playing! Take care of them, gain Daily Income, engage in profitable mini-games, go for a walk with your pet and much more!',
    titleImg: headerImg.src,
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
    soon: soonImg.src,
    golden1: imageGolden1.src,
    golden2: imageGolden2.src,
    goldenText: imageGoldenText.src,
  },
  nftsGroupList: [
    {
      name: 'Group1',
      cards: [
        {
          name: 'nfts1',
          title: 'NFTS 1',
          text: `Lorem ipsum dolor sit amet`,
          image: image1Nft.src,
          backImage: image1Nft.src,
        },
        {
          name: 'nfts2',
          title: 'NFTS 2',
          text: `Lorem ipsum dolor sit amet`,
          image: image2Nft.src,
          backImage: image2Nft.src,
        },
        {
          name: 'nfts3',
          title: 'NFTS 3',
          text: `Lorem ipsum dolor sit amet`,
          image: image3Nft.src,
          backImage: image3Nft.src,
        },
        {
          name: 'nfts4',
          title: 'NFTS 4',
          text: `Lorem ipsum dolor sit amet`,
          image: image4Nft.src,
          backImage: image4Nft.src,
        },
        {
          name: 'nfts5',
          title: 'NFTS 5',
          text: `Lorem ipsum dolor sit amet`,
          image: image5Nft.src,
          backImage: image5Nft.src,
        },
        {
          name: 'nfts6',
          title: 'NFTS 6',
          text: `Lorem ipsum dolor sit amet`,
          image: image6Nft.src,
          backImage: image6Nft.src,
        },
      ],
    },
  ],
  nftsList: [
    {
      name: 'golden',
      title: 'golden',
      text: `Lorem ipsum dolor sit amet`,
      image: image1Nft.src,
      backImage: image1Nft.src,
    },
    {
      name: 'nfts1',
      title: 'NFTS 1',
      text: `Lorem ipsum dolor sit amet`,
      image: image1Nft.src,
      backImage: image1Nft.src,
    },
    {
      name: 'nfts2',
      title: 'NFTS 2',
      text: `Lorem ipsum dolor sit amet`,
      image: image2Nft.src,
      backImage: image2Nft.src,
    },
    {
      name: 'nfts3',
      title: 'NFTS 3',
      text: `Lorem ipsum dolor sit amet`,
      image: image3Nft.src,
      backImage: image3Nft.src,
    },
    {
      name: 'nfts4',
      title: 'NFTS 4',
      text: `Lorem ipsum dolor sit amet`,
      image: image4Nft.src,
      backImage: image4Nft.src,
    },
    {
      name: 'nfts5',
      title: 'NFTS 5',
      text: `Lorem ipsum dolor sit amet`,
      image: image5Nft.src,
      backImage: image5Nft.src,
    },
    {
      name: 'nfts6',
      title: 'NFTS 6',
      text: `Lorem ipsum dolor sit amet`,
      image: image6Nft.src,
      backImage: image6Nft.src,
    },
  ],
};
