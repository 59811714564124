import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import background from '../../../../assets/images/welcome/bg.webp';
import { useIntersectionObserver } from '../../../../hooks/IntersectionObserver.hook';
import CarouselDesktopNavigator from './CarouselDesktopNavigator';
import { sectionsWelcome } from '../../../../utils/sectionWelcomeData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    margin: '0 auto',
    backgroundImage: `url(${background})`,
    backgroundPositionX: 'right',
    backgroundSize: 'cover',
    padding: '40px',
    height: '100vh',
    zIndex: -2,
  },
  contentText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      paddingBottom: '5px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      paddingBottom: '14px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '26px',
      paddingBottom: '16px',
    },
  },
  contentTextLast: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      paddingBottom: '5px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      paddingBottom: '14px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '26px',
      paddingBottom: '16px',
    },
  },
  title: {
    fontFamily: 'BlockBerthold',
    fontWeight: 400,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '50px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '60px',
    },
  },
  titleContainer: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '30px',
      paddingTop: '80px',
      fontSize: '8vh',
    },

    [theme.breakpoints.up('md')]: {
      paddingLeft: '30px',
      paddingTop: '110px',
    },
  },
  textContainer: {
    paddingTop: '32px',

    [theme.breakpoints.down('md')]: {
      paddingTop: '14px',
    },
  },
  titleAnimation: {
    opacity: 1,
    animation: 'fadeInDown 2s both 1',
  },
  textAnimation: {
    animation: 'fadeInLeft 2s both 1',
  },
  hiddenText: {
    opacity: 0,
  },
  bodyContainer: {
    maxWidth: '1440px',
    height: '100%',
    width: '100%',
    '& .MuiGrid-root': {
      zIndex: 10,
    },
  },
  nubeE: {
    top: '6%',
    left: '100%',
    width: '25%',
    position: 'absolute',
    zIndex: 1,
    animation: 'moveclouds 35s linear infinite',
  },
  nubeF: {
    top: '14%',
    left: '100%',
    width: '20%',
    position: 'absolute',
    zIndex: 1,
    animation: 'moveclouds 30s ease-in-out infinite',
  },
  cat: {
    bottom: '15%',
    left: '40%',
    width: '20%',
    position: 'absolute',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      width: '25%',
      left: '15%',
    },
    [theme.breakpoints.up('md')]: {
      width: '20%',
      left: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '15%',
      left: '55%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '15%',
      left: '55%',
    },
  },
  dog: {
    bottom: '15%',
    left: '60%',
    width: '30%',
    position: 'absolute',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      width: '35%',
      left: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
      left: '60%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '22%',
      left: '70%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '22%',
      left: '70%',
    },
  },
  dogGif: {
    bottom: '15%',
    left: '60%',
    width: '30%',
    position: 'absolute',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      width: '300px',
      left: '30%',
    },
    [theme.breakpoints.up('md')]: {
      width: '350px',
      left: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
      left: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '22%',
      left: '70%',
    },
  },
}));

const LandingPageDidYouKnow = () => {
  const classes = useStyles();
  const refSection = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const setTimer = () =>
    setTimeout(() => {
      setIsRunning(false);
    }, 500);

  const updateIndex = (newIndex: number) => {
    setIsRunning(true);
    if (newIndex >= 0 && newIndex < sectionsWelcome.cards.length) {
      setActiveIndex(newIndex);
      setTimer();
    } else if (newIndex >= sectionsWelcome.cards.length) {
      setActiveIndex(0);
      setTimer();
    }
  };

  const isSectionVisible = useIntersectionObserver(refSection, { threshold: 0 }, true);

  return (
    <>
      <div className={classes.root} id='welcome'>
        <img src={sectionsWelcome.pageInfo.cloud1} className={classes.nubeE} alt='cloud' />
        <img src={sectionsWelcome.pageInfo.cloud2} className={classes.nubeF} alt='cloud' />
        {/* <img src={sectionsWelcome.pageInfo.cat} className={classes.cat} alt='cat' /> */}
        <img src={sectionsWelcome.pageInfo.dogGif} className={classes.dogGif} alt='dog' />
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={classes.background}>
          <Grid
            direction='column'
            container
            alignItems='center'
            justifyContent='space-between'
            className={classes.bodyContainer}>
            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='center'
              className={`${classes.titleContainer} `}>
              <Grid item xs={12}>
                <span
                  ref={refSection}
                  className={`
                ${classes.title} 
                ${isSectionVisible && !isRunning ? classes.titleAnimation : classes.hiddenText} 
                `}>
                  {sectionsWelcome.cards[activeIndex].title}
                </span>
              </Grid>

              <Grid
                container
                xs={8}
                direction='row'
                alignItems='flex-start'
                justifyContent='flex-start'
                className={`
              ${classes.textContainer} 
              ${isSectionVisible && !isRunning ? classes.textAnimation : classes.hiddenText} 
              `}>
                {sectionsWelcome.cards[activeIndex].text.map((text, i) => (
                  <div
                    className={
                      i === sectionsWelcome.cards[activeIndex].text.length - 1
                        ? classes.contentTextLast
                        : classes.contentText
                    }
                    key={`text${i}`}>
                    {text}
                  </div>
                ))}
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <CarouselDesktopNavigator
              data={sectionsWelcome.cards}
              activeIndex={activeIndex}
              updateIndex={updateIndex}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageDidYouKnow;
