import cloud1 from '../assets/images/welcome/cloud_1.png';
import cloud2 from '../assets/images/welcome/cloud_2.png';
import cat from '../assets/images/welcome/cat.webp';
import dog from '../assets/images/welcome/dog.webp';
import dogGif from '../assets/images/welcome/dog.gif';

const cloud1Image = new Image();
cloud1Image.src = cloud1;
const cloud2Image = new Image();
cloud2Image.src = cloud2;
const dogImage = new Image();
dogImage.src = dog;
const dogGifImage = new Image();
dogGifImage.src = dogGif;
const catImage = new Image();
catImage.src = cat;

export const sectionsWelcome = {
  pageInfo: {
    title: 'Welcome',
    cloud1: cloud1Image.src,
    cloud2: cloud2Image.src,
    dog: dogImage.src,
    dogGif: dogGifImage.src,
    cat: catImage.src,
  },
  cards: [
    {
      name: 'welcome1',
      title: 'Play, Move & Earn!',
      text: [
        `Cats & Dogs combines play to earn (take care of and love your pet) `,
        `with the concept of Move to Earn (go for a walk with your dog or cat!)`,
      ],
    },
    {
      name: 'welcome2',
      title: 'Your Best Friends!',
      text: [
        `A precious valley for you to share with the cutest pets.`,
        `Fun and opportunity for all! A town of Pets living together, playing and growing.`,
        `Happiness and care will pay off!`,
      ],
    },
    {
      name: 'welcome3',
      title: 'Grow Together!',
      text: [
        `Take care of your Pets. They will grow and produce income.`,
        `Play daily mini-games to earn more! Use your Pets to interact with the world!`,
        `Your New Best Friends!`,
      ],
    },
  ],
};
