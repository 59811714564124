import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { Fragment } from 'react';
import { sectionsGoals } from '../../../../utils/sectionOurGoalData';
import CarouselComponent from './Carousel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  background: {
    backgroundImage: `url(${sectionsGoals.pageInfo.bgMobile})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -2,
  },
  bodyContainer: {
    maxHeight: '90%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0vh 10%',
  },
  imageHeader: {
    width: '25%',
  },
  imageChar1: {
    height: '25vh',
    bottom: '20px',
  },
  imageChar2: {
    height: '25vh',
    bottom: '20px',
  },
  imageChar3: {
    height: '25vh',
    bottom: '20px',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'BlockBerthold',
    fontWeight: 400,
    fontSize: '30px',
    marginTop: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '40px',
  },
  text: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '18px',
    marginTop: '12px',
  },
  carousel: {},
}));

const LandingOurGoalMobile = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root} id='ourGoals'>
        <Grid container direction='column' alignItems='center' className={classes.background}>
          <Grid container className={`${classes.bodyContainer}`}>
            <CarouselComponent className={classes.carousel}>
              {sectionsGoals.sections.map((section, index) => (
                <Fragment key={index}>
                  <Grid
                    direction='row'
                    container
                    alignItems='center'
                    justifyContent='center'
                    className={classes.contentContainer}>
                    <img src={section.imageHeader} alt='imageheader' className={classes.imageHeader} />
                    <div className={classes.textContainer}>
                      <span className={classes.title}>{section.title}</span>
                      {section.text.map((item, index) => (
                        <span key={index} className={classes.text}>
                          {item}
                        </span>
                      ))}
                    </div>

                    {index === 0 && <img src={section.slider1} alt='imageChar1' className={`${classes.imageChar1}`} />}
                    {index === 1 && <img src={section.slider2} alt='imagechar2' className={`${classes.imageChar2}`} />}
                    {index === 2 && <img src={section.slider3} alt='imagechar3' className={`${classes.imageChar3}`} />}
                  </Grid>
                </Fragment>
              ))}
            </CarouselComponent>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingOurGoalMobile;
