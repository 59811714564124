import alpha from '../assets/images/partners/logos/alpha.png';
import aws from '../assets/images/partners/logos/aws.png';
import apes from '../assets/images/partners/logos/apes.png';
import bagg from '../assets/images/partners/logos/bagg.png';
import cloudflare from '../assets/images/partners/logos/cloudflare.png';
import cryptopia from '../assets/images/partners/logos/cryptopia.png';
import desert from '../assets/images/partners/logos/desertWhales.png';
import evan from '../assets/images/partners/logos/evanLuthra.png';
import game from '../assets/images/partners/logos/gameStation.png';
import getSetPlay from '../assets/images/partners/logos/getSetPlay.png';
import photoverse from '../assets/images/partners/logos/photoVerse.png';
import router from '../assets/images/partners/logos/routerProtocol.png';
import scorpio from '../assets/images/partners/logos/scorpioVC.png';
import trustfi from '../assets/images/partners/logos/trustFi.png';
import unreal from '../assets/images/partners/logos/unrealCapital.png';
import yasar from '../assets/images/partners/logos/yasarCorporation.png';
import avg from '../assets/images/partners/logos/avg.png';
import azaGroup from '../assets/images/partners/logos/azaGroup.png';
import caduceus from '../assets/images/partners/logos/caduceus.png';
import falconCapital from '../assets/images/partners/logos/falconCapital.png';
import scottyBeam from '../assets/images/partners/logos/scottyBeam.png';
import dodo from '../assets/images/partners/logos/dodo.png';
import acknoledger from '../assets/images/partners/logos/acknoledger.webp';
import binamon from '../assets/images/partners/logos/binamon.webp';
import cryptoExecutives from '../assets/images/partners/logos/cryptoExecutives.webp';
import katanaInu from '../assets/images/partners/logos/katanaInu.webp';
import playSky from '../assets/images/partners/logos/playSky.webp';
import unity from '../assets/images/partners/logos/unity.webp';
import vulcanForged from '../assets/images/partners/logos/vulcanForged.webp';
import arc8 from '../assets/images/partners/logos/arc8.png';
import faraland from '../assets/images/partners/logos/faraland.png';
import alphaSquad from '../assets/images/partners/logos2/alphaSquad.png';
import annoyedRexClub from '../assets/images/partners/logos2/annoyedRexClub.png';
import blocksmithLabs from '../assets/images/partners/logos2/blocksmithLabs.png';
import botBorgs from '../assets/images/partners/logos2/botBorgs.png';
import cardboardCitizens from '../assets/images/partners/logos2/cardboardCitizens.png';
import cetsOnCreck from '../assets/images/partners/logos2/cetsOnCreck.png';
import famousFoxFederation from '../assets/images/partners/logos2/famousFoxFederation.png';
import flippinRabbits from '../assets/images/partners/logos2/flippinRabbits.png';
import froots from '../assets/images/partners/logos2/froots.png';
import ghostKidDAO from '../assets/images/partners/logos2/ghostKidDAO.png';
import greatGoats from '../assets/images/partners/logos2/greatGoats.png';
import jikanStudios from '../assets/images/partners/logos2/jikanStudios.png';
import monkelabs from '../assets/images/partners/logos2/Monkelabs.png';
import mousdainhouse from '../assets/images/partners/logos2/mousdainhouse.png';
import nodeMonkey from '../assets/images/partners/logos2/nodeMonkey.png';
import nyanHeroes from '../assets/images/partners/logos2/nyanHeroes.png';
import okayBears from '../assets/images/partners/logos2/okayBears.png';
import peskyPenguins from '../assets/images/partners/logos2/peskyPenguins.png';
import primates from '../assets/images/partners/logos2/primates.png';
import reptilianRegenade from '../assets/images/partners/logos2/reptilianRegenade.png';
import sentries from '../assets/images/partners/logos2/sentries.png';
import solRarity from '../assets/images/partners/logos2/solRarity.png';
import stonedApesCrew from '../assets/images/partners/logos2/stonedApesCrew.png';
import theOrcs from '../assets/images/partners/logos2/theOrcs.png';
import thugBirdz from '../assets/images/partners/logos2/thugBirdz.png';
import tombstone from '../assets/images/partners/logos2/tombstone.png';
import trippinApeTribe from '../assets/images/partners/logos2/trippinApeTribe.png';
import udderChaos from '../assets/images/partners/logos2/udderChaos.png';
import yawww from '../assets/images/partners/logos2/yawww.png';
import yeahTigers from '../assets/images/partners/logos2/yeahTigers.png';
import trustLabs from '../assets/images/partners/logos2/trustLabs.png';

const alphaImg = new Image();
alphaImg.src = alpha;

const awsImg = new Image();
awsImg.src = aws;

const apesImg = new Image();
apesImg.src = apes;

const baggImg = new Image();
baggImg.src = bagg;

const cloudflareImg = new Image();
cloudflareImg.src = cloudflare;

const cryptopiaImg = new Image();
cryptopiaImg.src = cryptopia;

const desertImg = new Image();
desertImg.src = desert;

const evanImg = new Image();
evanImg.src = evan;

const gameImg = new Image();
gameImg.src = game;

const getSetPlayImg = new Image();
getSetPlayImg.src = getSetPlay;

const photoverseImg = new Image();
photoverseImg.src = photoverse;

const routerImg = new Image();
routerImg.src = router;

const scorpioImg = new Image();
scorpioImg.src = scorpio;

const trustfiImg = new Image();
trustfiImg.src = trustfi;

const unrealImg = new Image();
unrealImg.src = unreal;

const yasarImg = new Image();
yasarImg.src = yasar;

const avgImg = new Image();
avgImg.src = avg;

const azaGroupImg = new Image();
azaGroupImg.src = azaGroup;

const caduceusImg = new Image();
caduceusImg.src = caduceus;

const falconCapitalImg = new Image();
falconCapitalImg.src = falconCapital;

const scottyBeamImg = new Image();
scottyBeamImg.src = scottyBeam;

const dodoImg = new Image();
dodoImg.src = dodo;

const acknoledgerImg = new Image();
acknoledgerImg.src = acknoledger;

const binamonImg = new Image();
binamonImg.src = binamon;

const cryptoExecutivesImg = new Image();
cryptoExecutivesImg.src = cryptoExecutives;

const katanaInuImg = new Image();
katanaInuImg.src = katanaInu;

const playSkyImg = new Image();
playSkyImg.src = playSky;

const unityImg = new Image();
unityImg.src = unity;

const vulcanForgedImg = new Image();
vulcanForgedImg.src = vulcanForged;

const arc8Img = new Image();
arc8Img.src = arc8;

const faralandImg = new Image();
faralandImg.src = faraland;

const alphaSquadImg = new Image();
alphaSquadImg.src = alphaSquad;

const annoyedRexClubImg = new Image();
annoyedRexClubImg.src = annoyedRexClub;

const blocksmithLabsImg = new Image();
blocksmithLabsImg.src = blocksmithLabs;

const botBorgsImg = new Image();
botBorgsImg.src = botBorgs;

const cardboardCitizensImg = new Image();
cardboardCitizensImg.src = cardboardCitizens;

const cetsOnCreckImg = new Image();
cetsOnCreckImg.src = cetsOnCreck;

const famousFoxFederationImg = new Image();
famousFoxFederationImg.src = famousFoxFederation;

const flippinRabbitsImg = new Image();
flippinRabbitsImg.src = flippinRabbits;

const frootsImg = new Image();
frootsImg.src = froots;

const ghostKidDAOImg = new Image();
ghostKidDAOImg.src = ghostKidDAO;

const greatGoatsImg = new Image();
greatGoatsImg.src = greatGoats;

const jikanStudiosImg = new Image();
jikanStudiosImg.src = jikanStudios;

const monkelabsImg = new Image();
monkelabsImg.src = monkelabs;

const mousdainhouseImg = new Image();
mousdainhouseImg.src = mousdainhouse;

const nodeMonkeyImg = new Image();
nodeMonkeyImg.src = nodeMonkey;

const nyanHeroesImg = new Image();
nyanHeroesImg.src = nyanHeroes;

const okayBearsImg = new Image();
okayBearsImg.src = okayBears;

const peskyPenguinsImg = new Image();
peskyPenguinsImg.src = peskyPenguins;

const primatesImg = new Image();
primatesImg.src = primates;

const reptilianRegenadeImg = new Image();
reptilianRegenadeImg.src = reptilianRegenade;

const sentriesImg = new Image();
sentriesImg.src = sentries;

const solRarityImg = new Image();
solRarityImg.src = solRarity;

const stonedApesCrewImg = new Image();
stonedApesCrewImg.src = stonedApesCrew;

const theOrcsImg = new Image();
theOrcsImg.src = theOrcs;

const thugBirdzImg = new Image();
thugBirdzImg.src = thugBirdz;

const tombstoneImg = new Image();
tombstoneImg.src = tombstone;

const trippinApeTribeImg = new Image();
trippinApeTribeImg.src = trippinApeTribe;

const udderChaosImg = new Image();
udderChaosImg.src = udderChaos;

const yawwwImg = new Image();
yawwwImg.src = yawww;

const yeahTigersImg = new Image();
yeahTigersImg.src = yeahTigers;

const trustLabsImg = new Image();
trustLabsImg.src = trustLabs;

export interface Logo {
  srcGray: string;
  srcColor: string;
  url: string;
}
export interface SectionPartners {
  name: string;
  logos: Logo[];
}

export const sectionsPartners: SectionPartners = {
  name: 'logos0',
  logos: [
    {
      srcGray: alphaImg.src,
      srcColor: alphaImg.src,
      url: 'https://alphaguilty.io/',
    },
    {
      srcGray: awsImg.src,
      srcColor: awsImg.src,
      url: 'https://aws.amazon.com/',
    },
    {
      srcGray: apesImg.src,
      srcColor: apesImg.src,
      url: 'https://apesgroup.net/',
    },
    {
      srcGray: baggImg.src,
      srcColor: baggImg.src,
      url: 'https://battlearena.gg/',
    },
    {
      srcGray: cloudflareImg.src,
      srcColor: cloudflareImg.src,
      url: 'https://www.cloudflare.com/',
    },
    {
      srcGray: cryptopiaImg.src,
      srcColor: cryptopiaImg.src,
      url: 'https://cryptopia-group.com/',
    },
    {
      srcGray: desertImg.src,
      srcColor: desertImg.src,
      url: 'https://desertwhales.com/',
    },
    {
      srcGray: evanImg.src,
      srcColor: evanImg.src,
      url: 'https://www.instagram.com/evanluthra/',
    },
    {
      srcGray: gameImg.src,
      srcColor: gameImg.src,
      url: 'https://www.gamestation.io/',
    },
    {
      srcGray: getSetPlayImg.src,
      srcColor: getSetPlayImg.src,
      url: 'https://getsetplay.io/',
    },
    {
      srcGray: photoverseImg.src,
      srcColor: photoverseImg.src,
      url: 'https://ref.protoverse.ai/',
    },
    {
      srcGray: routerImg.src,
      srcColor: routerImg.src,
      url: 'https://www.routerprotocol.com/',
    },
    {
      srcGray: scorpioImg.src,
      srcColor: scorpioImg.src,
      url: 'http://scorpio.vc/',
    },
    {
      srcGray: trustfiImg.src,
      srcColor: trustfiImg.src,
      url: 'https://trustfi.org/',
    },
    {
      srcGray: unrealImg.src,
      srcColor: unrealImg.src,
      url: 'https://ucapital.vc/',
    },
    {
      srcGray: yasarImg.src,
      srcColor: yasarImg.src,
      url: 'https://www.yasarcorp.com/',
    },
    {
      srcGray: avgImg.src,
      srcColor: avgImg.src,
      url: 'https://autoventure.group/',
    },
    {
      srcGray: azaGroupImg.src,
      srcColor: azaGroupImg.src,
      url: 'https://www.azagroups.co/',
    },
    {
      srcGray: caduceusImg.src,
      srcColor: caduceusImg.src,
      url: 'https://caduceus.foundation/',
    },
    {
      srcGray: falconCapitalImg.src,
      srcColor: falconCapitalImg.src,
      url: 'https://twitter.com/falcon_CapitaI',
    },
    {
      srcGray: scottyBeamImg.src,
      srcColor: scottyBeamImg.src,
      url: 'https://scottybeam.io/',
    },
    {
      srcGray: dodoImg.src,
      srcColor: dodoImg.src,
      url: 'https://dodoex.io',
    },
    {
      srcGray: acknoledgerImg.src,
      srcColor: acknoledgerImg.src,
      url: 'https://acknoledger.com/',
    },
    {
      srcGray: binamonImg.src,
      srcColor: binamonImg.src,
      url: 'https://binamon.org/',
    },
    {
      srcGray: cryptoExecutivesImg.src,
      srcColor: cryptoExecutivesImg.src,
      url: '#',
    },
    {
      srcGray: katanaInuImg.src,
      srcColor: katanaInuImg.src,
      url: 'https://katanainu.com/',
    },
    {
      srcGray: playSkyImg.src,
      srcColor: playSkyImg.src,
      url: 'https://playsky.io/#/',
    },
    {
      srcGray: unityImg.src,
      srcColor: unityImg.src,
      url: 'https://unity.com/',
    },
    {
      srcGray: vulcanForgedImg.src,
      srcColor: vulcanForgedImg.src,
      url: 'https://vulcanforged.com/',
    },
    {
      srcGray: arc8Img.src,
      srcColor: arc8Img.src,
      url: 'https://www.gamee.com/arc8/',
    },
    {
      srcGray: faralandImg.src,
      srcColor: faralandImg.src,
      url: 'https://faraland.io/',
    },
    {
      srcGray: alphaSquadImg.src,
      srcColor: alphaSquadImg.src,
      url: 'https://twitter.com/AlphaSquad_NFT',
    },
    {
      srcGray: annoyedRexClubImg.src,
      srcColor: annoyedRexClubImg.src,
      url: 'https://www.annoyedrexclub.com/',
    },
    {
      srcGray: blocksmithLabsImg.src,
      srcColor: blocksmithLabsImg.src,
      url: 'https://www.blocksmithlabs.com/',
    },
    {
      srcGray: botBorgsImg.src,
      srcColor: botBorgsImg.src,
      url: 'https://botborgs.com/',
    },
    {
      srcGray: cardboardCitizensImg.src,
      srcColor: cardboardCitizensImg.src,
      url: 'https://www.cardboardcitizen.com/',
    },
    {
      srcGray: cetsOnCreckImg.src,
      srcColor: cetsOnCreckImg.src,
      url: 'https://cetsoncreck.com/',
    },
    {
      srcGray: famousFoxFederationImg.src,
      srcColor: famousFoxFederationImg.src,
      url: 'https://famousfoxes.com/',
    },
    {
      srcGray: flippinRabbitsImg.src,
      srcColor: flippinRabbitsImg.src,
      url: 'https://flippinrabbits.xyz/',
    },
    {
      srcGray: frootsImg.src,
      srcColor: frootsImg.src,
      url: 'https://frootsnft.com/',
    },
    {
      srcGray: ghostKidDAOImg.src,
      srcColor: ghostKidDAOImg.src,
      url: 'https://www.ghostkid.io/',
    },
    {
      srcGray: greatGoatsImg.src,
      srcColor: greatGoatsImg.src,
      url: 'https://greatgoats.io/',
    },
    {
      srcGray: jikanStudiosImg.src,
      srcColor: jikanStudiosImg.src,
      url: 'https://www.jikanstudios.io/',
    },
    {
      srcGray: monkelabsImg.src,
      srcColor: monkelabsImg.src,
      url: 'https://monkelabs.io/',
    },
    {
      srcGray: mousdainhouseImg.src,
      srcColor: mousdainhouseImg.src,
      url: 'https://mousindahous.com/',
    },
    {
      srcGray: nodeMonkeyImg.src,
      srcColor: nodeMonkeyImg.src,
      url: 'https://twitter.com/nodemonkeynft?lang=es',
    },
    {
      srcGray: nyanHeroesImg.src,
      srcColor: nyanHeroesImg.src,
      url: 'https://nyanheroes.com/',
    },
    {
      srcGray: okayBearsImg.src,
      srcColor: okayBearsImg.src,
      url: 'https://www.okaybears.com/',
    },
    {
      srcGray: peskyPenguinsImg.src,
      srcColor: peskyPenguinsImg.src,
      url: 'https://pesky-penguins.com/',
    },
    {
      srcGray: primatesImg.src,
      srcColor: primatesImg.src,
      url: 'https://twitter.com/Primatesnft',
    },
    {
      srcGray: reptilianRegenadeImg.src,
      srcColor: reptilianRegenadeImg.src,
      url: 'https://www.reptilian-renegade.com/',
    },
    {
      srcGray: sentriesImg.src,
      srcColor: sentriesImg.src,
      url: 'https://www.sentries.io/',
    },
    {
      srcGray: solRarityImg.src,
      srcColor: solRarityImg.src,
      url: 'https://solrarity.app/dashboard',
    },
    {
      srcGray: stonedApesCrewImg.src,
      srcColor: stonedApesCrewImg.src,
      url: 'https://www.stonedapecrew.com/',
    },
    {
      srcGray: theOrcsImg.src,
      srcColor: theOrcsImg.src,
      url: 'https://theorcs.io/',
    },
    {
      srcGray: thugBirdzImg.src,
      srcColor: thugBirdzImg.src,
      url: 'https://www.thugbirdz.com/',
    },
    {
      srcGray: tombstoneImg.src,
      srcColor: tombstoneImg.src,
      url: 'https://www.tombstoned.xyz/',
    },
    {
      srcGray: trippinApeTribeImg.src,
      srcColor: trippinApeTribeImg.src,
      url: 'https://www.trippinapetribe.xyz/',
    },
    {
      srcGray: udderChaosImg.src,
      srcColor: udderChaosImg.src,
      url: 'https://www.udderchaos.io/',
    },
    {
      srcGray: yawwwImg.src,
      srcColor: yawwwImg.src,
      url: 'https://www.yawww.io/',
    },
    {
      srcGray: yeahTigersImg.src,
      srcColor: yeahTigersImg.src,
      url: 'https://www.yeahtigers.com/',
    },
    {
      srcGray: trustLabsImg.src,
      srcColor: trustLabsImg.src,
      url: 'https://www.trustlabs.app/',
    },
  ],
};
