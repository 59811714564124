import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { sectionNfts } from '../../../../utils/sectionNftsData';
import CarouselComponent from './Carousel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    backgroundImage: `url(${sectionNfts.pageInfo.bgMobile})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    zIndex: -2,
  },
  contentContainer: {
    padding: '12vh 10%',
    zIndex: 9,
  },
  imageFront: {
    width: '100%',
    zIndex: 10,
  },
  imageBack: {
    width: '100%',
    zIndex: 10,
  },
  headerImage: {
    width: '90%',
  },
  contentText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '15px',
    maxWidth: '100%',
    lineHeight: '15pt',
    fontSize: '12pt',
  },
  carousel: {
    // maxHeight: '65%',
    // marginTop: '-30px',
  },
  soon: {
    margin: '0 auto',
    position: 'absolute',
    width: '80%',
    bottom: '10vh',
    zIndex: 30,
  },
  nftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  golden: {
    width: '75%',
  },
  goldenText: {
    paddingTop: '20px',
    width: '90%',
  },
  animationSoon: {
    transitionDelay: '0s, 1000ms',
    animation: 'slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both',
  },
}));

const LandingNftsMobile = () => {
  const classes = useStyles();
  const [golden, setGolden] = useState<string>('');
  const [flippedIndex, setFlippedIndex] = useState(-1);

  return (
    <>
      <div className={classes.root} id='nfts'>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={`${classes.background} ${classes.contentContainer}`}>
          <Grid container direction='column' alignItems='center' justifyContent='center'>
            <img
              aria-hidden='true'
              src={sectionNfts.pageInfo.titleImg}
              alt='imageheader'
              className={classes.headerImage}
            />
          </Grid>

          <CarouselComponent className={classes.carousel} scrollButtons>
            {sectionNfts.nftsList.map((section, index) => (
              <div key={index} className={classes.nftContainer}>
                {index === 0 && (
                  <div>
                    <img
                      src={golden === 'show' ? sectionNfts.pageInfo.golden2 : sectionNfts.pageInfo.golden1}
                      onTouchStart={() => setGolden('show')}
                      onTouchEnd={() => setGolden('')}
                      alt='golden'
                      className={classes.golden}
                    />
                    <img
                      src={sectionNfts.pageInfo.goldenText}
                      alt='exclusive vip'
                      className={`${classes.goldenText} ${classes.animationSoon}`}
                    />
                  </div>
                )}
                {index > 0 && (
                  <ReactCardFlip
                    isFlipped={index === flippedIndex}
                    flipDirection='horizontal'
                    flipSpeedBackToFront={1}
                    flipSpeedFrontToBack={1}>
                    <img
                      aria-hidden='true'
                      src={section.image}
                      alt='imageheader'
                      className={classes.imageFront}
                      onClick={() => setFlippedIndex(index)}
                    />
                    <img
                      aria-hidden='true'
                      src={section.backImage}
                      alt='imageheader'
                      className={classes.imageBack}
                      onClick={() => setFlippedIndex(-1)}
                    />
                  </ReactCardFlip>
                )}
              </div>
            ))}
          </CarouselComponent>
        </Grid>
      </div>
    </>
  );
};

export default LandingNftsMobile;
