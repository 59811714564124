import teamB1 from '../assets/images/teamAdv/keyMembers/01-Team-Card-Pablo-Back.webp';
import team1 from '../assets/images/teamAdv/keyMembers/01-Team-Card-Pablo-Front.webp';
import teamB2 from '../assets/images/teamAdv/keyMembers/02-Team-Card-Evan-Back.webp';
import team2 from '../assets/images/teamAdv/keyMembers/02-Team-Card-Evan-Front.webp';
import teamB3 from '../assets/images/teamAdv/keyMembers/03-Team-Card-Denis-Back.webp';
import team3 from '../assets/images/teamAdv/keyMembers/03-Team-Card-Denis-Front.webp';
import teamB4 from '../assets/images/teamAdv/keyMembers/04-Team-Card-Angel-Back.webp';
import team4 from '../assets/images/teamAdv/keyMembers/04-Team-Card-Angel-Front.webp';
import teamB5 from '../assets/images/teamAdv/keyMembers/05-Team-Card-Fede-Back.webp';
import team5 from '../assets/images/teamAdv/keyMembers/05-Team-Card-Fede-Front.webp';
import teamB6 from '../assets/images/teamAdv/keyMembers/06-Team-Card-Giga-Back.webp';
import team6 from '../assets/images/teamAdv/keyMembers/06-Team-Card-Giga-Front.webp';
import teamB7 from '../assets/images/teamAdv/keyMembers/07-Team-Card-Douglas-Back.webp';
import team7 from '../assets/images/teamAdv/keyMembers/07-Team-Card-Douglas-Front.webp';
import teamB8 from '../assets/images/teamAdv/keyMembers/08-Team-Card-Arnaldo-Back.webp';
import team8 from '../assets/images/teamAdv/keyMembers/08-Team-Card-Arnaldo-Front.webp';
import teamB9 from '../assets/images/teamAdv/keyMembers/09-Team-Card-Juani-Back.webp';
import team9 from '../assets/images/teamAdv/keyMembers/09-Team-Card-Juani-Front.webp';
import teamB10 from '../assets/images/teamAdv/keyMembers/10-Team-Card-Marce-Back.webp';
import team10 from '../assets/images/teamAdv/keyMembers/10-Team-Card-Marce-Front.webp';
import teamB11 from '../assets/images/teamAdv/keyMembers/11-Team-Card-Arturo-Back.webp';
import team11 from '../assets/images/teamAdv/keyMembers/11-Team-Card-Arturo-Front.webp';
import teamB12 from '../assets/images/teamAdv/keyMembers/12-Team-Card-Robby-Back.webp';
import team12 from '../assets/images/teamAdv/keyMembers/12-Team-Card-Robby-Front.webp';
import teamB13 from '../assets/images/teamAdv/keyMembers/13-Team-Card-DaniSpain-Back.png';
import team13 from '../assets/images/teamAdv/keyMembers/13-Team-Card-DaniSpain-Front.png';
import teamB14 from '../assets/images/teamAdv/keyMembers/14-Team-Card-DrSolana-Back.png';
import team14 from '../assets/images/teamAdv/keyMembers/14-Team-Card-DrSolana-Front.png';
import teamB15 from '../assets/images/teamAdv/keyMembers/15-Team-Card-Sp00n1cus-Back.png';
import team15 from '../assets/images/teamAdv/keyMembers/15-Team-Card-Sp00n1cus-Front.png';

import bg from '../assets/images/teamAdv/bg.webp';
import bgMobile from '../assets/images/teamAdv/bgMobile.webp';
import header from '../assets/images/teamAdv/headerKey.webp';
import linkedinImage from '../assets/images/teamAdv/linkedinButton.webp';
import linkedinHoverImage from '../assets/images/teamAdv/linkedinHoverButton.webp';
import instagram from '../assets/images/teamAdv/instagramButton.webp';
import instagramHover from '../assets/images/teamAdv/instagramHoverButton.webp';
import link from '../assets/images/teamAdv/linkButton.webp';
import linkHover from '../assets/images/teamAdv/linkHoverButton.webp';
import button from '../assets/images/teamAdv/button.webp';
import logo from '../assets/images/teamAdv/ApesLogo.webp';
import cdlogo from '../assets/images/teamAdv/CDLogo.webp';
import cdlogoBN from '../assets/images/teamAdv/CDLogoBN.webp';
import twitterHover from '../assets/images/teamAdv/twitterHoverButton.png';
import twitter from '../assets/images/teamAdv/twitterButton.png';

const logoImg = new Image();
logoImg.src = logo;

const cdlogoImg = new Image();
cdlogoImg.src = cdlogo;

const cdlogoBNImg = new Image();
cdlogoBNImg.src = cdlogoBN;

const buttonImg = new Image();
buttonImg.src = button;

const instagramImg = new Image();
instagramImg.src = instagram;

const twitterImg = new Image();
twitterImg.src = twitter;

const twitterHImg = new Image();
twitterHImg.src = twitterHover;

const instagramHImg = new Image();
instagramHImg.src = instagramHover;

const linkedinImg = new Image();
linkedinImg.src = linkedinImage;

const linkedinHoverImg = new Image();
linkedinHoverImg.src = linkedinHoverImage;

const linkImg = new Image();
linkImg.src = link;

const linkHoverImg = new Image();
linkHoverImg.src = linkHover;

const headerImg = new Image();
headerImg.src = header;

const bgImg = new Image();
bgImg.src = bg;

const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const team1Image = new Image();
team1Image.src = team1;
const teamB1Image = new Image();
teamB1Image.src = teamB1;

const team2Image = new Image();
team2Image.src = team2;
const teamB2Image = new Image();
teamB2Image.src = teamB2;

const team3Image = new Image();
team3Image.src = team3;
const teamB3Image = new Image();
teamB3Image.src = teamB3;

const team4Image = new Image();
team4Image.src = team4;
const teamB4Image = new Image();
teamB4Image.src = teamB4;

const team5Image = new Image();
team5Image.src = team5;
const teamB5Image = new Image();
teamB5Image.src = teamB5;

const team6Image = new Image();
team6Image.src = team6;
const teamB6Image = new Image();
teamB6Image.src = teamB6;

const team7Image = new Image();
team7Image.src = team7;
const teamB7Image = new Image();
teamB7Image.src = teamB7;

const team8Image = new Image();
team8Image.src = team8;
const teamB8Image = new Image();
teamB8Image.src = teamB8;

const team9Image = new Image();
team9Image.src = team9;
const teamB9Image = new Image();
teamB9Image.src = teamB9;

const team10Image = new Image();
team10Image.src = team10;
const teamB10Image = new Image();
teamB10Image.src = teamB10;

const team11Image = new Image();
team11Image.src = team11;
const teamB11Image = new Image();
teamB11Image.src = teamB11;

const team12Image = new Image();
team12Image.src = team12;
const teamB12Image = new Image();
teamB12Image.src = teamB12;

const team13Image = new Image();
team13Image.src = team13;
const teamB13Image = new Image();
teamB13Image.src = teamB13;

const team14Image = new Image();
team14Image.src = team14;
const teamB14Image = new Image();
teamB14Image.src = teamB14;

const team15Image = new Image();
team15Image.src = team15;
const teamB15Image = new Image();
teamB15Image.src = teamB15;

export const sectionTeam = {
  pageInfo: {
    title: 'Team & ADV',
    logo: logoImg.src,
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
    cdLogo: cdlogoImg.src,
    cdLogoBn: cdlogoBNImg.src,
    button: buttonImg.src,
    text: [
      { text: 'Cats & Dogs is a unique game owned by Cats and Dogs Corporation.' },
      { text: 'The Game has been originally developed from the ground-up by our Art & Development Team.' },
      { text: 'We are a team of professionals working for you: we are excited to introduce ourselves!' },
    ],
    fullText:
      'Cats & Dogs is a unique game owned by Cats and Dogs Corporation. The Game has been originally developed from the ground-up by our Art & Development Team. We are a team of professionals working for you: we are excited to introduce ourselves!',
    titleImg: headerImg.src,
    linkedinImg: linkedinImg.src,
    linkedinHoverImg: linkedinHoverImg.src,
    instagram: instagramImg.src,
  },
  teamList: [
    {
      cards1: [
        {
          name: 'team1',
          title: 'Pablo Moauro',
          text: 'CEO',
          link: 'https://www.linkedin.com/in/pablo-moauro-71a9276a',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team1Image.src,
          back: teamB1Image.src,
          description: 'Chief Economist & MBA',
        },
        {
          name: 'team2',
          title: 'Evan Luthra',
          text: `Marketing Advisor`,
          link: 'https://www.instagram.com/evanluthra/',
          socialImg: instagramImg.src,
          socialHoverImg: instagramHImg.src,
          image: team2Image.src,
          back: teamB2Image.src,
          description: '2.5M followers on Instagram & Forbes 30 under 30',
        },
        {
          name: 'team12',
          title: 'Robby Jeo',
          text: ``,
          link: 'https://www.linkedin.com/in/robby678/',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team12Image.src,
          back: teamB12Image.src,
          description: '',
        },
        {
          name: 'team4',
          title: 'Angel',
          text: '',
          link: 'https://www.linkedin.com/in/angel-tremosa-86807a33/',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team4Image.src,
          back: teamB4Image.src,
          description: '',
        },
      ],
    },
    {
      cards2: [
        {
          name: 'team5',
          title: 'Fede',
          text: '',
          link: 'https://www.linkedin.com/in/federico-prat-65a68532/',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team5Image.src,
          back: teamB5Image.src,
          description: '',
        },
        {
          name: 'team6',
          title: 'Giga Bokuchava',
          text: `Fundraising Advisor`,
          link: 'https://www.linkedin.com/in/gigabokuchava/',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team6Image.src,
          back: teamB6Image.src,
          description: 'Capital Raising & Partnerships from A+ Ventures',
        },
        {
          name: 'team8',
          title: 'Arnaldo Corzo',
          text: ``,
          link: 'https://www.linkedin.com/in/aferrercrafter/',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team8Image.src,
          back: teamB8Image.src,
          description: '',
        },
        {
          name: 'team14',
          title: 'Dr. Solana',
          text: ``,
          link: 'https://twitter.com/DrSolanaNFT',
          socialImg: twitterImg.src,
          socialHoverImg: twitterHImg.src,
          image: team14Image.src,
          back: teamB14Image.src,
          description: '',
        },
      ],
    },
    {
      cards3: [
        {
          name: 'team9',
          title: 'Juan Ignacio De Marco',
          text: ``,
          link: 'https://www.linkedin.com/in/juanigdemarco/',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team9Image.src,
          back: teamB9Image.src,
          description: '',
        },
        {
          name: 'team10',
          title: 'Marcelo Funes',
          text: ``,
          link: 'https://www.linkedin.com/in/marcelofunes/',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team10Image.src,
          back: teamB10Image.src,
          description: '',
        },
        {
          name: 'team11',
          title: 'Denis ScottyBeam',
          text: ``,
          link: 'https://scottybeam.io/',
          socialImg: linkImg.src,
          socialHoverImg: linkHoverImg.src,
          image: team3Image.src,
          back: teamB3Image.src,
          description: '',
        },
        {
          name: 'team7',
          title: 'Douglas Eriksen',
          text: `Product Advisor`,
          link: 'https://www.linkedin.com/in/douglas-eriksen/',
          socialImg: linkedinImg.src,
          socialHoverImg: linkedinHoverImg.src,
          image: team7Image.src,
          back: teamB7Image.src,
          description: 'from Ultra.io',
        },
      ],
    },
    {
      cards4: [
        {
          name: 'team13',
          title: 'Dani Spain',
          text: ``,
          link: 'https://twitter.com/DManSpain1',
          socialImg: twitterImg.src,
          socialHoverImg: twitterHImg.src,
          image: team13Image.src,
          back: teamB13Image.src,
          description: '',
        },
        {
          name: 'team15',
          title: 'Sp00n1cus',
          text: ``,
          link: 'https://twitter.com/sp00n1cus',
          socialImg: twitterImg.src,
          socialHoverImg: twitterHImg.src,
          image: team15Image.src,
          back: teamB15Image.src,
          description: '',
        },
        {
          name: 'logo',
          title: '',
          text: ``,
          link: 'https://www.linkedin.com/company/cats-and-dogs-nft/',
          socialImg: buttonImg.src,
          socialHoverImg: buttonImg.src,
          image: cdlogoImg.src,
          back: cdlogoBNImg.src,
          description: '',
        },
      ],
    },
  ],
  teamListMobile: [
    {
      name: 'team1',
      title: 'Pablo Moauro',
      text: 'CEO',
      link: 'https://www.linkedin.com/in/pablo-moauro-71a9276a',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team1Image.src,
      back: teamB1Image.src,
      description: 'Chief Economist & MBA',
    },
    {
      name: 'team2',
      title: 'Evan Luthra',
      text: `Marketing Advisor`,
      link: 'https://www.instagram.com/evanluthra/',
      socialImg: instagramImg.src,
      socialHoverImg: instagramHImg.src,
      image: team2Image.src,
      back: teamB2Image.src,
      description: '2.5M followers on Instagram & Forbes 30 under 30',
    },
    {
      name: 'team12',
      title: 'Robby Jeo',
      text: ``,
      link: 'https://www.linkedin.com/in/robby678/',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team12Image.src,
      back: teamB12Image.src,
      description: '',
    },
    {
      name: 'team4',
      title: 'Angel',
      text: '',
      link: 'https://www.linkedin.com/in/angel-tremosa-86807a33/',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team4Image.src,
      back: teamB4Image.src,
      description: '',
    },
    {
      name: 'team5',
      title: 'Fede',
      text: '',
      link: 'https://www.linkedin.com/in/federico-prat-65a68532/',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team5Image.src,
      back: teamB5Image.src,
      description: '',
    },
    {
      name: 'team6',
      title: 'Giga Bokuchava',
      text: `Fundraising Advisor`,
      link: 'https://www.linkedin.com/in/gigabokuchava/',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team6Image.src,
      back: teamB6Image.src,
      description: 'Capital Raising & Partnerships from A+ Ventures',
    },
    {
      name: 'team7',
      title: 'Douglas Eriksen',
      text: `Product Advisor`,
      link: 'https://www.linkedin.com/in/douglas-eriksen/',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team7Image.src,
      back: teamB7Image.src,
      description: 'from Ultra.io',
    },
    {
      name: 'team8',
      title: 'Arnaldo Corzo',
      text: ``,
      link: 'https://www.linkedin.com/in/aferrercrafter/',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team8Image.src,
      back: teamB8Image.src,
      description: '',
    },
    {
      name: 'team9',
      title: 'Juan Ignacio De Marco',
      text: ``,
      link: 'https://www.linkedin.com/in/juanigdemarco/',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team9Image.src,
      back: teamB9Image.src,
      description: '',
    },
    {
      name: 'team10',
      title: 'Marcelo Funes',
      text: ``,
      link: 'https://www.linkedin.com/in/marcelofunes/',
      socialImg: linkedinImg.src,
      socialHoverImg: linkedinHoverImg.src,
      image: team10Image.src,
      back: teamB10Image.src,
      description: '',
    },
    {
      name: 'team3',
      title: 'Denis ScottyBeam',
      text: ``,
      link: 'https://scottybeam.io/',
      socialImg: linkImg.src,
      socialHoverImg: linkHoverImg.src,
      image: team3Image.src,
      back: teamB3Image.src,
      description: '',
    },
    {
      name: 'team13',
      title: 'Dani Spain',
      text: ``,
      link: 'https://twitter.com/DManSpain1',
      socialImg: twitterImg.src,
      socialHoverImg: twitterHImg.src,
      image: team13Image.src,
      back: teamB13Image.src,
      description: '',
    },
    {
      name: 'team14',
      title: 'Dr. Solana',
      text: ``,
      link: 'https://twitter.com/DrSolanaNFT',
      socialImg: twitterImg.src,
      socialHoverImg: twitterHImg.src,
      image: team14Image.src,
      back: teamB14Image.src,
      description: '',
    },
    {
      name: 'team15',
      title: 'Sp00n1cus',
      text: ``,
      link: 'https://twitter.com/sp00n1cus',
      socialImg: twitterImg.src,
      socialHoverImg: twitterHImg.src,
      image: team15Image.src,
      back: teamB15Image.src,
      description: '',
    },
    {
      name: 'logo',
      title: '',
      text: ``,
      link: 'https://www.linkedin.com/company/cats-and-dogs-nft/',
      socialImg: buttonImg.src,
      socialHoverImg: buttonImg.src,
      image: cdlogoImg.src,
      back: cdlogoBNImg.src,
      description: '',
    },
  ],
};
