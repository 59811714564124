export const sectionList = [
  { key: 'welcome', value: 'Welcome!', page: 1 },
  { key: 'gameView', value: 'Game View', page: 2 },
  { key: 'ourGoal', value: 'Our Goal', page: 3 },
  { key: 'letsMove', value: 'Let´s Move!', page: 4 },
  { key: 'nfts', value: 'NFTs', page: 5 },
  { key: 'team', value: 'Team & ADV', page: 6 },
  { key: 'roadMap', value: 'Road Map', page: 7 },
  { key: 'partners', value: 'Partners', page: 8 },
  { key: 'docs', value: 'Documents', page: 9 },
];

export const sectionListMobile = [
  { key: 'heroMobile', value: 'Home', page: 0 },
  { key: 'welcomeMobile', value: 'Welcome!', page: 1 },
  { key: 'gameViewMobile', value: 'Game View', page: 2 },
  { key: 'ourGoalMobile', value: 'Our Goal', page: 3 },
  { key: 'letsMoveMobile', value: 'Let´s Move!', page: 4 },
  { key: 'nftsMobile', value: 'NFTs', page: 5 },
  { key: 'teamMobile', value: 'Team & ADV', page: 6 },
  { key: 'roadMapMobile', value: 'Road Map', page: 7 },
  { key: 'partnersMobile', value: 'Partners', page: 8 },
  { key: 'docsMobile', value: 'Documents', page: 9 },
];
