import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { sectionDocs } from '../../../../utils/sectionDocsData';
import { useIntersectionObserver } from '../../../../hooks/IntersectionObserver.hook';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    background: '#68030F',
    textAlign: 'center',
  },
  background: {
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url(${sectionDocs.pageInfo.bg})`,
    backgroundSize: 'cover',
    height: '100vh',
    zIndex: -2,
  },
  cardsContainer: {
    maxWidth: '1440px',
    justifyContent: 'space-evenly',
    width: '95%',
    paddingTop: '18vh',
    [theme.breakpoints.only('lg')]: {
      paddingTop: '20vh',
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
  },
  cardContent: {
    justifyContent: 'start',
    width: '80%',
    marginLeft: '10px',
    marginTop: '5vh',
    padding: '0px 15px',
    zIndex: 9,
    height: '40vh',
    [theme.breakpoints.only('sm')]: {
      marginTop: '20vh',
      marginLeft: '0px',
      padding: '0px 0px',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '15vh',
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '10vh',
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '15vh',
    },
  },
  imageDoc: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 5,
    [theme.breakpoints.down('md')]: {
      maxWidth: '35vh',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '45vh',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '55vh',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '46vh',
    },
  },
  cardText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    color: '#68030F',
    lineHeight: '24px',
    fontSize: '20px',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      lineHeight: '18px',
      fontSize: '15px',
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: '24px',
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      lineHeight: '28px',
      fontSize: '24px',
    },
  },
  iconImage: {
    [theme.breakpoints.only('sm')]: {
      width: '10vh',
    },
    [theme.breakpoints.only('md')]: {
      width: '13vh',
    },
    [theme.breakpoints.only('lg')]: {
      width: '15vh',
    },
    [theme.breakpoints.only('xl')]: {
      width: '13vh',
    },
  },
  buttonImage: {
    marginTop: '20px',
    [theme.breakpoints.only('sm')]: {
      marginTop: '10px',
      width: '15vh',
    },
    [theme.breakpoints.only('md')]: {
      width: '18vh',
    },
    [theme.breakpoints.only('lg')]: {
      width: '20vh',
    },
    [theme.breakpoints.only('xl')]: {
      width: '18vh',
    },
  },
  animationContainer: {
    animation: 'fadeoutfadeinTeam 2s linear',
  },
  animation: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility linear 0s, opacity 4000ms 500ms',
  },
  animation1: { transitionDelay: '0s, 400ms' },
  textAnimation: {
    opacity: 0,
    animation: 'jackInTheBox 2s both 1',
    position: 'absolute',
    top: '90px',
    [theme.breakpoints.only('lg')]: {
      top: '80px',
    },
    [theme.breakpoints.only('xl')]: {
      top: '100px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerImage: {
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '400px',
    },
  },
}));

const LandingPageDocs = () => {
  const classes = useStyles();
  const [classNameSet, setClassName] = useState('');
  const [hoverOn, setHoverOn] = useState('');
  const refSection = useRef(null);
  const isSectionVisible = useIntersectionObserver(refSection, { threshold: 0 }, true);

  useEffect(() => {
    setClassName(classes.animationContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classNameSet]);

  const handleButton = (link: string) => {
    window.open(link, '_blank', 'noopener');
  };

  return (
    <>
      <div className={classes.root} id='documents'>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={classes.background}>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            className={`${isSectionVisible ? classes.textAnimation : ''}`}
            ref={refSection}>
            {isSectionVisible ? (
              <Grid item xs={12} className={classes.title}>
                <img src={sectionDocs.pageInfo.titleImg} alt='imageheader' className={classes.headerImage} />
              </Grid>
            ) : null}
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            className={`${classes.cardsContainer} ${classNameSet}`}>
            {sectionDocs.cards.map((doc) => (
              <Grid item xs={3} container justifyContent='center' key={`${doc.name} doc`} className={classNameSet}>
                <Grid className={classes.cardContainer}>
                  <img aria-hidden='true' src={doc.image} alt='image doc' className={classes.imageDoc} />
                  <Grid container direction='column' alignItems='center' className={classes.cardContent}>
                    <img src={doc.icon} alt={doc.name} className={classes.iconImage} aria-hidden='true' />
                    <span className={classes.cardText}>{doc.text}</span>
                    <img
                      src={hoverOn === doc.name ? sectionDocs.pageInfo.buttonHoverImg : sectionDocs.pageInfo.buttonImg}
                      alt='more info'
                      className={classes.buttonImage}
                      onClick={() => handleButton(doc.url)}
                      aria-hidden='true'
                      onMouseEnter={() => setHoverOn(`${doc.name}`)}
                      onMouseLeave={() => setHoverOn('')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageDocs;
