import bg from '../assets/images/roadMap/bg.webp';
import bgMobile from '../assets/images/roadMap/bgMobile.webp';
import header from '../assets/images/roadMap/header.webp';
import road1 from '../assets/images/roadMap/box0.webp';
import road2 from '../assets/images/roadMap/box1.webp';
import road3 from '../assets/images/roadMap/box2.webp';
import road4 from '../assets/images/roadMap/box3.webp';

const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;
const bgImg = new Image();
bgImg.src = bg;
const headerImg = new Image();
headerImg.src = header;
const image1Road = new Image();
image1Road.src = road1;
const image2Road = new Image();
image2Road.src = road2;
const image3Road = new Image();
image3Road.src = road3;
const image4Road = new Image();
image4Road.src = road4;

export const sectionRoadMap = {
  pageInfo: {
    title: 'Road Map',
    titleImg: headerImg.src,
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
  },
  cards: [
    {
      name: 'road1',
      image: image1Road.src,
    },
    {
      name: 'road2',
      image: image2Road.src,
    },
    {
      name: 'road3',
      image: image3Road.src,
    },
    {
      name: 'road4',
      image: image4Road.src,
    },
  ],
};
