import { Grid, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import ReactPageScroller from 'react-page-scroller';
import { Element } from 'react-scroll';
import { useIntersectionObserver } from '../../hooks/IntersectionObserver.hook';
import LandingPageMenu from './components/landingPage/LandingPageMenu';
import LandingPageHeader from './components/landingPage/LandingPageHeader';
import LandingPageWelcome from './components/landingPage/LandingPageWelcome';
import LandingPageGameView from './components/landingPage/LandingPageGameView';
import LandingPageOurGoal from './components/landingPage/LandingPageOurGoal';
import LandingPageLetsMove from './components/landingPage/LandingPageLetsMove';
import LandingNfts from './components/landingPage/LandingPageNfts';
import LandingPageTeamAndADV from './components/landingPage/LandingPageTeamAndADV';
import LandingPageRoadMap from './components/landingPage/LandingPageRoadMap';
import LandingPagePartners from './components/landingPage/LandingPagePartners';
import LandingPageDocs from './components/landingPage/LandingPageDocs';
import LandingPageContactUs from './components/landingPage/LandingPageContactUs';
import SocialMediaButtons from './components/SocialMediaButtons';

import LandingPageMenuMobile from './components/landingPageMobile/LandingPageMenuMobile';
import LandingPageHeaderMobile from './components/landingPageMobile/LandingPageHeaderMobile';
import LandingPageWelcomeMobile from './components/landingPageMobile/LandingPageWelcomeMobile';
import LandingPageGameViewMobile from './components/landingPageMobile/LandingPageGameViewMobile';
import LandingPageOurGoalMobile from './components/landingPageMobile/LandingPageOurGoalMobile';
import LandingPageLetsMoveMobile from './components/landingPageMobile/LandingPageLetsMoveMobile';
import LandingNftsMobile from './components/landingPageMobile/LandingPageNftsMobile';
import LandingPageTeamAndADVMobile from './components/landingPageMobile/LandingPageTeamAndADVMobile';
import LandingPageRoadMapMobile from './components/landingPageMobile/LandingPageRoadMapMobile';
import LandingPagePartnersMobile from './components/landingPageMobile/LandingPagePartnersMobile';
import LandingPageDocsMobile from './components/landingPageMobile/LandingPageDocsMobile';
import LandingPageContactUsMobile from './components/landingPageMobile/LandingPageContactUsMobile';
import SocialMediaButtonsMobile from './components/SocialMediaButtonsMobile';
//import VideoPlayer from './components/VideoPlayer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'hidden',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  rootMobile: {
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'hidden',
    display: 'flex',
  },
  '@keyframes shake': {
    '0%': {
      transform: 'rotate(1deg)',
    },

    '50%': {
      transform: 'rotate(-1deg)',
    },

    '100%': {
      transform: 'rotate(1deg)',
    },
  },
  '@keyframes fluent': {
    '0%': {
      opacity: '0',
      transform: 'translate(-50%,-70%)',
    },
    '100%': {
      opacity: '1',
      transform: 'translate(-50%,-50%)',
    },
  },
  comingsoon: {
    width: '45vh',
  },
  mobileAsset: {
    width: '40vh',
    position: 'fixed',
    bottom: '-40px',
    left: 0,
  },
  mobileBar: {
    position: 'fixed',
    width: '100%',
    display: 'block',
    top: 0,
    left: 0,
  },
  logoImage: {
    position: 'fixed',
    top: '21px',
    left: '20%',
    width: '32vh',
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const heroRef = useRef(null);
  const welcomeRef = useRef(null);
  const gameViewRef = useRef(null);
  const ourGoalRef = useRef(null);
  const letsMoveRef = useRef(null);
  const nftsRef = useRef(null);
  const roadMapRef = useRef(null);
  const partnersRef = useRef(null);
  const docsRef = useRef(null);
  const contactUsRef = useRef(null);
  const rootRefMobile = useRef(null);
  const heroRefMobile = useRef(null);
  const welcomeRefMobile = useRef(null);
  const gameViewRefMobile = useRef(null);
  const ourGoalRefMobile = useRef(null);
  const letsMoveRefMobile = useRef(null);
  const nftsRefMobile = useRef(null);
  const roadMapRefMobile = useRef(null);
  const partnersRefMobile = useRef(null);
  const docsRefMobile = useRef(null);
  const teamRefMobile = useRef(null);
  const contactUsRefMobile = useRef(null);
  const contactUsRefMobileDiv = useRef(null);
  const [isMediaVisible, setIsMediaVisible] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedMenu, setExpandedMenu] = useState(false);
  const [miniMenu, setMiniMenu] = useState(false);
  const onScrollBottom = (e: any) => {
    console.log('scroll');
    if (e.nativeEvent.wheelDeltaY > 100) {
      setIsMediaVisible(true);
    }
  };
  const handleOnDocumentBottom = () => {
    setIsMediaVisible(false);
  };

  const handlePageChange = (number: number) => {
    if (number <= 0) {
      setCurrentPage(0);
      setMiniMenu(false);
    } else if (number > 10) {
      setCurrentPage(10);
    } else {
      setCurrentPage(number);
      setMiniMenu(true);
    }
    if (number === 10) {
      setIsMediaVisible(false);
    } else {
      setIsMediaVisible(true);
    }
  };

  const handleExpandedMenu = (value: any) => {
    setExpandedMenu(value);
  };

  let isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
    },
  };

  const isBottomVisibleMobile = useIntersectionObserver(contactUsRefMobileDiv, { threshold: 0 }, false);

  if (isMobile.Android() || isMobile.iOS()) {
    return (
      <>
        {!isBottomVisibleMobile && <SocialMediaButtonsMobile></SocialMediaButtonsMobile>}
        <div className={classes.rootMobile}>
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            direction='column'
            wrap='nowrap'
            ref={rootRefMobile}>
            <Grid item onTouchStart={() => setMiniMenu(false)}>
              <LandingPageMenuMobile
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                handleExpandedMenu={handleExpandedMenu}
                expandedMenu={expandedMenu}
                handleMiniMenu={miniMenu}
              />
            </Grid>
            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='heroMobile' ref={heroRefMobile}>
                <LandingPageHeaderMobile />
              </Element>
            </Grid>
            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='welcomeMobile' ref={welcomeRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingPageWelcomeMobile />
              </Element>
            </Grid>
            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='gameViewMobile' ref={gameViewRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingPageGameViewMobile />
              </Element>
            </Grid>
            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='ourGoalMobile' ref={ourGoalRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingPageOurGoalMobile />
              </Element>
            </Grid>
            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='letsMoveMobile' ref={letsMoveRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingPageLetsMoveMobile />
              </Element>
            </Grid>
            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='nftsMobile' ref={nftsRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingNftsMobile />
              </Element>
            </Grid>
            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='teamMobile' ref={teamRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingPageTeamAndADVMobile />
              </Element>
            </Grid>

            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='roadMapMobile' ref={roadMapRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingPageRoadMapMobile />
              </Element>
            </Grid>

            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='partnersMobile' ref={partnersRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingPagePartnersMobile />
              </Element>
            </Grid>
            <Grid item onTouchStart={() => setMiniMenu(true)}>
              <Element name='docsMobile' ref={docsRefMobile} style={{ position: 'relative', width: '100vw' }}>
                <LandingPageDocsMobile />
              </Element>
            </Grid>
            <Grid item ref={contactUsRefMobileDiv} onTouchStart={() => setMiniMenu(true)}>
              <Element name='contactUsMobile' style={{ position: 'relative', width: '100vw' }} ref={contactUsRefMobile}>
                <LandingPageContactUsMobile handlePageChange={handlePageChange} currentPage={currentPage} />
              </Element>
            </Grid>
          </Grid>
        </div>
      </>
    );
  } else {
    return (
      <>
        {/* <VideoPlayer /> */}
        <div className={classes.root}>
          <BottomScrollListener onBottom={handleOnDocumentBottom} />
          {isMediaVisible && <SocialMediaButtons></SocialMediaButtons>}
          <Grid container direction='column' wrap='nowrap' ref={rootRef} onScroll={onScrollBottom}>
            <Grid item>
              <LandingPageMenu
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                handleExpandedMenu={handleExpandedMenu}
                expandedMenu={expandedMenu}
              />
            </Grid>
            <ReactPageScroller
              pageOnChange={handlePageChange}
              customPageNumber={currentPage}
              renderAllPagesOnFirstRender>
              <Grid item>
                <Element name='hero' ref={heroRef}>
                  <LandingPageHeader />
                </Element>
              </Grid>
              <Grid item>
                <Element name='welcome' ref={welcomeRef} style={{ position: 'relative' }}>
                  <LandingPageWelcome />
                </Element>
              </Grid>
              <Grid item>
                <Element name='gameView' ref={gameViewRef} style={{ position: 'relative' }}>
                  <LandingPageGameView />
                </Element>
              </Grid>
              <Grid item>
                <Element name='ourGoal' ref={ourGoalRef} style={{ position: 'relative' }}>
                  <LandingPageOurGoal />
                </Element>
              </Grid>
              <Grid item>
                <Element name='letsMove' ref={letsMoveRef} style={{ position: 'relative' }}>
                  <LandingPageLetsMove />
                </Element>
              </Grid>
              <Grid item>
                <Element name='nfts' ref={nftsRef} style={{ position: 'relative' }}>
                  <LandingNfts />
                </Element>
              </Grid>
              <Grid item>
                <Element name='team' style={{ position: 'relative' }}>
                  <LandingPageTeamAndADV />
                </Element>
              </Grid>

              <Grid item>
                <Element name='roadMap' ref={roadMapRef} style={{ position: 'relative' }}>
                  <LandingPageRoadMap />
                </Element>
              </Grid>

              <Grid item>
                <Element name='partners' ref={partnersRef} style={{ position: 'relative' }}>
                  <LandingPagePartners />
                </Element>
              </Grid>
              <Grid item>
                <Element name='docs' ref={docsRef} style={{ position: 'relative' }}>
                  <LandingPageDocs />
                </Element>
              </Grid>
              <Grid item>
                <Element name='contactUs' style={{ position: 'relative' }} ref={contactUsRef}>
                  <LandingPageContactUs handlePageChange={handlePageChange} currentPage={currentPage} />
                </Element>
              </Grid>
            </ReactPageScroller>
          </Grid>
        </div>
      </>
    );
  }
};

export default LandingPage;
