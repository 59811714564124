import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useRef } from 'react';
import { useIntersectionObserver } from '../../../../hooks/IntersectionObserver.hook';
import { sectionRoadMap } from '../../../../utils/sectionRoadMapData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    background: '#68030F',
    textAlign: 'center',
  },
  container: {
    margin: '0 auto',
    backgroundImage: `url(${sectionRoadMap.pageInfo.bg})`,
    backgroundSize: 'cover',
    padding: '40px',
    height: '100vh',
    zIndex: -2,
    [theme.breakpoints.down('md')]: {
      paddingBottom: '0px',
    },
  },
  sectionContent: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-evenly',
  },
  bodyContainer: {
    maxWidth: '1440px',
    margin: 'auto',
    [theme.breakpoints.down('xl')]: {
      paddingTop: '50px',
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '105px',
    },
  },
  cardContainer: {
    zIndex: 1,
    marginTop: '4vh',
    [theme.breakpoints.only('sm')]: {
      width: '30vh',
      marginTop: 0,
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '8vh',
    },
  },
  card: {
    opacity: 0,
    [theme.breakpoints.only('sm')]: {
      height: '35vh',
    },
    [theme.breakpoints.only('md')]: {
      height: '42vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '52vh',
    },
    [theme.breakpoints.only('xl')]: {
      height: '55vh',
    },
  },
  animation: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility linear 0s, opacity 5000ms 500ms',
  },
  titleAnimation: {
    animation: 'tilt-in-fwd-tr 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
  },
  animation0: {
    transitionDelay: '0s, 1000ms',
    animation: 'slide-in-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both',
  },
  animation1: {
    transitionDelay: '0s, 2000ms',
    animation: 'slide-in-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s both',
  },
  animation2: {
    transitionDelay: '0s, 3000ms',
    animation: 'slide-in-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s both',
  },
  animation3: {
    transitionDelay: '0s, 4000ms',
    animation: 'slide-in-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 4s both',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerImage: {
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '400px',
    },
  },
}));

const LandingPageRoadMap = () => {
  const classes = useStyles();
  const refSection = useRef(null);
  const isSectionVisible = useIntersectionObserver(refSection, { threshold: 0 }, true);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            ref={refSection}
            className={classes.bodyContainer}>
            <Grid item xs={12} className={classes.title}>
              <img
                src={sectionRoadMap.pageInfo.titleImg}
                alt='imageheader'
                className={`${isSectionVisible ? classes.titleAnimation : ''} ${classes.headerImage}`}
              />
            </Grid>
            <Grid className={classes.sectionContent} container direction='row' ref={refSection}>
              {sectionRoadMap.cards.map((card, index) => (
                <Grid item key={card.name} className={`${classes.cardContainer}`}>
                  <img
                    src={card.image}
                    alt={card.name}
                    className={`${isSectionVisible ? classes.animation : ''} ${classes.card} 
                    ${
                      index === 1
                        ? isSectionVisible && classes.animation1
                        : index === 2
                        ? isSectionVisible && classes.animation2
                        : index === 3
                        ? isSectionVisible && classes.animation3
                        : index === 0
                        ? isSectionVisible && classes.animation0
                        : null
                    }`}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LandingPageRoadMap;
