import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { sectionTeam } from '../../../../utils/sectionTeamData';
import CorpLogo from '../../../../assets/images/LogoCorp/LogoCorp.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    backgroundImage: `url(${sectionTeam.pageInfo.bgMobile})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    zIndex: -2,
  },
  imageLogo: {
    width: 'auto',
    height: '38vh',
    zIndex: 10,
    marginTop: '3vh',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  cardTeamContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  headerImage: {
    width: '90%',
  },
  contentContainer: {
    padding: '10vh 10%',
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10px',
    maxWidth: '95%',
  },
  text: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    color: 'white',
    paddingTop: '4px',
    fontSize: '12pt',
  },
  animationLogo: {
    //animation: 'pulse 5s infinite',
  },
  linkedinImage: {
    position: 'absolute',
    bottom: '2vh',
    width: '5vh',
    zIndex: 20,
  },
  carousel: {
    maxHeight: '45vh',
    marginTop: '-35px',
  },
  imageTeam: {
    marginTop: '3vh',
    height: '38vh',
  },
  docImage: {
    position: 'absolute',
    bottom: '5vh',
    width: '10vh',
    zIndex: 20,
  },
  cursor: {
    cursor: 'pointer',
  },
  logoCorp: {
    height: '200px',
    marginBottom: '50px',
  },
}));

const LandingPageTeamAndADVMobile = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root} id='team'>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={`${classes.background} ${classes.contentContainer}`}>
          <Grid container direction='column' alignItems='center' justifyContent='center'>
            <img
              aria-hidden='true'
              src={sectionTeam.pageInfo.titleImg}
              alt='imageheader'
              className={classes.headerImage}
            />
            <div className={classes.contentText}>
              {sectionTeam.pageInfo.text.map((item, index) => (
                <span key={`item-${index}`} className={classes.text}>
                  {item.text}
                </span>
              ))}
            </div>
          </Grid>
          <img className={classes.logoCorp} src={CorpLogo} alt='' />
        </Grid>
      </div>
    </>
  );
};

export default LandingPageTeamAndADVMobile;
