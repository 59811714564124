import { Divider, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-scroll';
import mobileMenuChar from '../../../assets/images/menu/menuMobileFooter.webp';
import arrowDisabled from '../../../assets/images/menu/backButton.webp';
import arrowEnabled from '../../../assets/images/menu/backButtonHover.webp';
import { sectionListMobile } from '../../../utils/sectionMenu';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
    content: "' '",
    backgroundColor: 'rgba(0, 0, 0, 0.5)!important',
    zIndex: 100,
    top: '0px',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    inset: '0px',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  root: {
    height: '100vh',
    overflow: 'hidden',
    top: '0px',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1800,
    width: '0%',
    right: '0px',
    alignItems: 'center',
    backgroundColor: '#BB4A30',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  header: {
    height: '60px',
    width: '100%',
    color: 'white',
  },
  body: {
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    textAlign: 'start',
    width: '100%',
    paddingLeft: '20px',
  },
  logo: {
    width: '175px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  buttonText: {
    fontFamily: 'BloggerSans',
    fontWeight: 400,
    padding: '7px 15px',
    display: 'block',
    color: 'white',
    '&:hover': {
      transitionProperty: 'font-size',
      transitionDuration: '2s',
      fontFamily: 'BloggerSansBold',
      fontWeight: 700,
      color: '#23D9FF',
    },
  },

  closeButton: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    background: 'transparent',
    width: '40px',
    marginTop: '10px',
    marginRight: '20px',
  },
  buttonTextLanguage: {
    marginTop: '30px',
    color: 'white',
    fontSize: '1rem',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  menuItemActive: {
    '& a': {
      fontSize: '1rem',
    },
  },
  menuItemActiveIndividual: {
    fontSize: '1.7em',
  },
  buttonTextWP: {
    fontFamily: 'BloggerSans',
    textTransform: 'uppercase',
    padding: '10px 13px',
    [theme.breakpoints.up('md')]: {
      padding: '1px 10px',
      fontSize: '1.3rem',
    },
  },
  divider: {
    backgroundColor: 'white',
  },
  charMenu: {
    width: '90%',
    marginTop: '20px',
  },
}));

type MenuProps = {
  menuType: 'landpage-consumer' | 'landpage-agent' | 'searcher';
  onClose: Function;
  open: boolean;
  handlePageChange: any;
  currentPage: number;
};

const MenuMobile: React.FC<MenuProps> = ({
  menuType = '',
  onClose = Function.prototype,
  open,
  handlePageChange,
  currentPage,
}) => {
  const classes = useStyles();
  const [closeMenu, setCloseMenu] = useState(false);
  const [, setOpenMenu] = useState(open);

  const animationClass = useMemo(
    () => (open ? 'animation-menu-open-mobile' : closeMenu ? 'animation-menu-close-mobile' : ''),
    [open, closeMenu],
  );

  useEffect(
    () => (open ? document.body.classList.add('menu-open') : document.body.classList.remove('menu-open')),
    [open, closeMenu],
  );

  function handleCloseMenu() {
    setOpenMenu(false);
    setCloseMenu(true);
    onClose();
  }

  const openGame = () => {
    window.open('https://play.catsanddogs.io/', '_blank', 'noopener');
  };

  return (
    <>
      {open && (
        <div
          className={classes.container}
          onClick={handleCloseMenu}
          onKeyDown={handleCloseMenu}
          aria-hidden='true'></div>
      )}

      <div className={`${classes.root} ${animationClass}`}>
        <div className={`${classes.header} text-right`}>
          <img
            onClick={handleCloseMenu}
            onFocus={() => void 0}
            aria-hidden='true'
            className={classes.closeButton}
            src={open ? arrowDisabled : arrowEnabled}
            alt='Logo Cats and Dogs'
          />
        </div>
        <div className={classes.body}>
          <div>
            {sectionListMobile.map((section) => (
              <span key={section.key}>
                <Link
                  activeClass={classes.menuItemActive}
                  to={section.key}
                  spy={true}
                  smooth={true}
                  offset={0}
                  key={section.key}
                  duration={1000}
                  onClick={() => {
                    handlePageChange(section.page);
                    handleCloseMenu();
                  }}
                  onFocus={() => void 0}
                  aria-hidden='true'>
                  <span className={classes.buttonText}>{section.value}</span>
                </Link>
                <Divider className={classes.divider} />
              </span>
            ))}
            <Link
              activeClass='active'
              to='hero'
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => openGame()}
              onFocus={() => void 0}
              aria-hidden='true'>
              <span className={classes.buttonText}>{'Play Now'}</span>
            </Link>
          </div>
          <div>
            <img className={classes.charMenu} src={mobileMenuChar} alt='Cats and Dogs' />
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuMobile;
