import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CarouselDesktopNavigator from './CarouselDesktopNavigator';
import { sectionsGoals } from '../../../../utils/sectionOurGoalData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  background: {
    margin: '0 auto',
    backgroundImage: `url(${sectionsGoals.pageInfo.bg})`,
    backgroundSize: 'cover',
    height: '100vh',
    zIndex: -2,
  },
  background2: {
    margin: '0 auto',
    backgroundImage: `url(${sectionsGoals.pageInfo.bg2})`,
    backgroundSize: 'cover',
    height: '100vh',
    zIndex: -2,
  },
  animationRight: {
    animation: 'lightSpeedInRight 2s ease-out',
  },
  contentText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 0,
    indexZ: 9,
    [theme.breakpoints.down('md')]: {
      lineHeight: '22px',
      fontSize: '18px',
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: '24px',
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      lineHeight: '28px',
      fontSize: '24px',
    },
  },
  title: {
    textAlign: 'center',
    fontFamily: 'BlockBerthold',
    display: 'block',
    fontWeight: 400,
    marginBottom: '40px',
    indexZ: 9,
    [theme.breakpoints.down('md')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '42px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '45px',
    },
  },
  imageHeader: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '146px',
    [theme.breakpoints.down('md')]: {
      width: '12vh',
    },
    [theme.breakpoints.up('md')]: {
      width: '18vh',
    },
    [theme.breakpoints.up('lg')]: {
      width: '20vh',
    },
  },
  imageChar1: {
    width: '45vh',
    display: 'block',
    position: 'absolute',
    bottom: '120px',
    left: '80px',
    indexZ: 1,
    animation: 'fadeInLeft 1s ease-out',
    [theme.breakpoints.down('md')]: {
      width: '45vh',
      left: '25%',
    },
  },
  imageChar2: {
    width: '45vh',
    display: 'block',
    position: 'absolute',
    bottom: '120px',
    left: '80px',
    indexZ: 1,
    animation: 'fadeInLeft 1s ease-out',
    [theme.breakpoints.down('md')]: {
      width: '45vh',
      left: '25%',
    },
  },
  imageChar3: {
    width: '45vh',
    display: 'block',
    position: 'absolute',
    bottom: '120px',
    left: '80px',
    indexZ: 1,
    animation: 'fadeInLeft 1s ease-out',
    [theme.breakpoints.down('md')]: {
      width: '45vh',
      left: '25%',
    },
  },
  starsContainer1: {
    position: 'absolute',
    bottom: '60vh',
    left: '40vh',
    animation: 'fadeInLeft 2s ease-out',
    transform: 'rotate(-20deg)',
    [theme.breakpoints.down('md')]: {
      bottom: '45vh',
      left: '30vh',
    },
  },
  starsContainer2: {
    position: 'absolute',
    bottom: '57vh',
    left: '35vh',
    animation: 'fadeInLeft 2s ease-out',
    transform: 'rotate(5deg)',
    [theme.breakpoints.down('md')]: {
      bottom: '47vh',
      left: '25vh',
    },
  },
  starsContainer3: {
    position: 'absolute',
    bottom: '60vh',
    left: '40vh',
    animation: 'fadeInLeft 2s ease-out',
    [theme.breakpoints.down('md')]: {
      bottom: '50vh',
      left: '30vh',
    },
  },
  imageStar1: {
    width: '67px',
    display: 'block',
    position: 'absolute',
    bottom: '60px',
    left: '100px',
    indexZ: 1,
    animation: 'shineStar1 8s infinite',
  },
  imageStar2: {
    width: '6vh',
    display: 'block',
    position: 'absolute',
    bottom: '30px',
    left: '0px',
    indexZ: 1,
    animation: 'shineStar2 8s infinite',
  },
  imageStar3: {
    width: '6vh',
    display: 'block',
    position: 'absolute',
    bottom: '-10px',
    left: '220px',
    animation: 'shineStar3 8s infinite',
  },
  bodyContainer: {
    position: 'absolute',
    height: '100vh',
    backgroundImage: `url(${sectionsGoals.pageInfo.bgShadow})`,
    backgroundSize: '100% 100%',
    indexZ: -1,
  },
  contentContainer: {
    maxWidth: '1440px',
    height: '70vh',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    [theme.breakpoints.down('xl')]: {
      margin: '0px 10px 0px 10px',
    },
  },
  rightContainer: {
    zIndex: 9,
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: '120px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 120px',
    },
  },
  animationContainer: {
    animation: 'fadeInRight 1.5s ease-in-out',
  },
  firstAnimation: {
    animation: 'fadeInDown 2s both 1',
  },
  imageAnimation: {
    animation: 'fadeInLeft 2s both 1',
  },
}));

const LandingOurGoal = () => {
  const classes = useStyles();
  const refSection = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [classNameSet, setClassName] = useState('');

  useEffect(() => {
    setClassName(classes.animationContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const setTimer = () =>
    setTimeout(() => {
      setClassName(classes.animationContainer);
    }, 500);

  const updateIndex = (newIndex: number) => {
    setClassName('');
    if (newIndex >= 0 && newIndex < sectionsGoals.sections.length) {
      setActiveIndex(newIndex);
      setTimer();
    } else if (newIndex >= sectionsGoals.sections.length) {
      setActiveIndex(0);
      setTimer();
    }
  };

  return (
    <>
      <div className={classes.root} id='ourGoals'>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          className={window.screen.height > 768 ? classes.background : classes.background2}>
          <Grid
            direction='row'
            container
            alignItems='center'
            justifyContent='center'
            className={`${classes.bodyContainer} ${classNameSet}`}>
            <Grid
              direction='row'
              container
              alignItems='center'
              justifyContent='center'
              className={classes.contentContainer}>
              <Grid item xs={2} md={4} ref={refSection}></Grid>
              <Grid item xs={10} md={8} className={`${classes.rightContainer}`}>
                <Grid container alignItems='center'>
                  <img
                    src={sectionsGoals.sections[activeIndex].imageHeader}
                    alt='imageheader'
                    className={classes.imageHeader}
                  />
                </Grid>
                <Grid container alignItems='center' justifyContent='center'>
                  <span className={classes.title}>{sectionsGoals.sections[activeIndex].title}</span>
                </Grid>
                <Grid container alignItems='center' justifyContent='center'>
                  {sectionsGoals.sections[activeIndex].text.map((text, i) => (
                    <p className={classes.contentText} key={`goal${i}`}>
                      {text}
                    </p>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CarouselDesktopNavigator data={sectionsGoals.sections} activeIndex={activeIndex} updateIndex={updateIndex} />
        </Grid>
        {activeIndex === 0 && (
          <div>
            <img
              src={sectionsGoals.sections[activeIndex].slider1}
              alt='imageChar1'
              className={`${classes.imageChar1}`}
            />
          </div>
        )}
        {activeIndex === 1 && (
          <div>
            <img
              src={sectionsGoals.sections[activeIndex].slider2}
              alt='imagechar2'
              className={`${classes.imageChar2}`}
            />
          </div>
        )}
        {activeIndex === 2 && (
          <div>
            <img
              src={sectionsGoals.sections[activeIndex].slider3}
              alt='imagechar3'
              className={`${classes.imageChar3}`}
            />
          </div>
        )}
        <div
          className={
            activeIndex === 0
              ? `${classes.starsContainer1} ${classNameSet}`
              : activeIndex === 1
              ? `${classes.starsContainer2} ${classNameSet}`
              : activeIndex === 2
              ? `${classes.starsContainer3} ${classNameSet}`
              : ' '
          }>
          {/* <div>
            <img src={sectionsGoals[activeIndex].imageStar1} alt='imageStar1' className={`${classes.imageStar1}`} />
          </div>
          <div>
            <img src={sectionsGoals[activeIndex].imageStar2} alt='imageStar2' className={`${classes.imageStar2}`} />
          </div>
          <div>
            <img src={sectionsGoals[activeIndex].imageStar3} alt='imageStar3' className={`${classes.imageStar3}`} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LandingOurGoal;
