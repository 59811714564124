/* eslint-disable jsx-a11y/media-has-caption */
import { makeStyles, Theme, Grid } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import background from '../../../../assets/images/gameView/bg.webp';
import border from '../../../../assets/images/gameView/border.webp';
import { sectionsGameView } from '../../../../utils/sectionGameViewData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: 'white',
  },
  gridContainer: {
    position: 'absolute',
    zIndex: 10,
    top: '10%',
    maxWidth: '100%',
    [theme.breakpoints.down('xl')]: {
      margin: '0px 10px 0px 10px',
    },
    [theme.breakpoints.down('md')]: {
      top: '20%',
    },
    width: '100%',
    height: 'auto',
    '&>iframe': {
      width: '70%',
    },
  },
  gridContainer2: {
    position: 'absolute',
    zIndex: 10,
    top: '15%',
    maxWidth: '100%',
    [theme.breakpoints.down('xl')]: {
      margin: '0px 10px 0px 10px',
    },
    [theme.breakpoints.down('md')]: {
      top: '20%',
    },
    width: '100%',
    height: 'auto',
    '&>iframe': {
      width: '70%',
    },
  },
  videoVimeo: {
    borderImage: `url(${border}) 30 20 stretch`,
    borderImageSlice: '70',
    borderWidth: '37px',
    borderStyle: 'solid',
    width: '70vh',
    height: '65vh',
    [theme.breakpoints.down('md')]: {
      height: '50vh',
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '40px',
    },
  },
  assetContainer: {
    backgroundImage: `url(${background})`,
    backgroundPositionY: 'bottom',
    backgroundSize: 'cover',
    margin: '0 auto',
    height: '100vh',
    paddingTop: 40,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    justifySelf: 'center',
    alignSelf: 'center',
    justifyContent: 'space-around',
    display: 'flex',
  },
  borderContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    marginBottom: '30px',
    '&>img': {
      width: '100%',
      //height: '100%',
      position: 'absolute',
    },
  },
  contentContainer: {
    maxWidth: '1440px',
    [theme.breakpoints.down('xl')]: {
      margin: '0px 10px 0px 10px',
    },
  },
  rightContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: '0 16vh',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 120px',
    },
  },
  animationContainer: {
    animation: 'fadeoutfadeinV 2s linear',
  },
}));

const LandingPageGameView = () => {
  const classes = useStyles();
  const [classNameSet, setClassName] = useState('');
  const videoRef1 = useRef(null);

  useEffect(() => {
    setClassName(classes.animationContainer);
  }, [classes.animationContainer]);

  return (
    <div className={classes.root} id='GameView'>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='space-between'
        className={classes.assetContainer}>
        <Grid
          direction='row'
          container
          alignItems='center'
          justifyContent='center'
          className={`${window.screen.height > 768 ? classes.gridContainer : classes.gridContainer2} ${classNameSet}`}>
          {/* <video
            width='320'
            height='240'
            controls
            ref={videoRef1}
            className={classes.videoVimeo}
            style={{ display: 'block' }}>
            <source type='video/mp4' src={sectionsGameView[0].src} />
          </video> */}
          <iframe
            width='100%'
            height='100%'
            ref={videoRef1}
            className={classes.videoVimeo}
            src={sectionsGameView[0].src}
            frameBorder='0'
            allow='fullscreen; accelerometer; autoplay; encrypted-media; picture-in-picture'
            allowFullScreen
            title='video'></iframe>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPageGameView;
