import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useRef } from 'react';
import { useIntersectionObserver } from '../../../../hooks/IntersectionObserver.hook';
import { sectionTeam } from '../../../../utils/sectionTeamData';
import CorpLogo from '../../../../assets/images/LogoCorp/LogoCorp.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    margin: '0 auto',
    backgroundImage: `url(${sectionTeam.pageInfo.bg})`,
    backgroundSize: 'cover',
    padding: '40px',
    height: '100vh',
    zIndex: -2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '0px',
    },
  },
  animationContainer: {
    animation: 'fadeoutfadeinTeam 0.5s linear',
  },
  imageAnimation: {
    opacity: 0,
  },
  textAnimation: {
    opacity: 0,
    animation: 'jackInTheBox 2s both 1',
    position: 'absolute',
    top: '90px',
    [theme.breakpoints.only('lg')]: {
      top: '80px',
    },
    [theme.breakpoints.only('xl')]: {
      top: '100px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerImage: {
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '400px',
    },
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '25px',
    maxWidth: '90%',
  },
  text: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    color: 'white',
    paddingTop: '4px',
    fontSize: '14pt',
    [theme.breakpoints.down('md')]: {
      paddingTop: '4px',
      fontSize: '12pt',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '4px',
      fontSize: '14pt',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '4px',
      fontSize: '16pt',
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '10px',
      fontSize: '20pt',
    },
  },
  text2: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    color: 'white',
    paddingTop: '3px',
    fontSize: '13pt',
  },
  logoShadow: {
    width: '100%',
    boxShadow: '0 5px 20px 2px #00000080',
    borderRadius: '50%',
  },
  imageLogo: {
    width: '100%',
  },
  animationLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  containerLogo: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingTop: '20vh',
      maxWidth: '40vh',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '25vh',
      maxWidth: '40vh',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '28vh',
      maxWidth: '40vh',
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '28vh',
      maxWidth: '40vh',
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  cardsContainer: {
    animation: 'fadeoutfadeinTeam 1s linear',
    maxWidth: '1440px',
    justifyContent: 'space-evenly',
    position: 'absolute',
    bottom: '100px',
    width: '80%',
    [theme.breakpoints.only('sm')]: {
      width: '95%',
      bottom: '120px',
    },
    [theme.breakpoints.only('md')]: {
      bottom: '90px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '85%',
    },
    [theme.breakpoints.only('xl')]: {
      width: '90%',
      bottom: '120px',
    },
  },
  imageTeam: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '30vh',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '35vh',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '35vh',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '35vh',
    },
  },
  linkedinImage: {
    position: 'absolute',
    bottom: '30px',
    width: '5vh',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      maxWidth: '4vh',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '5vh',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '8vh',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '5vh',
    },
  },
  docImage: {
    position: 'absolute',
    bottom: '50px',
    width: '10vh',
    cursor: 'pointer',
  },
  cursor: {
    cursor: 'pointer',
  },
  logoCorp: {
    height: '300px',
    marginTop: '220px',
    animation: 'jackInTheBox 2s both 1',
  },
}));

const LandingPageTeamAndADV = () => {
  const classes = useStyles();

  const refSection = useRef(null);
  const isSectionVisible = useIntersectionObserver(refSection, { threshold: 0 }, true);

  return (
    <div className={classes.root} id='nfts'>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='space-between'
        className={classes.background}>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
          className={`${isSectionVisible ? classes.textAnimation : ''}`}
          ref={refSection}>
          {isSectionVisible && (
            <Grid container direction='column' alignItems='center' justifyContent='center'>
              <img src={sectionTeam.pageInfo.titleImg} alt='imageheader' className={classes.headerImage} />
              <div className={classes.contentText}>
                {sectionTeam.pageInfo.text.map((item, index) => (
                  <span key={`item-${index}`} className={window.screen.height > 768 ? classes.text : classes.text2}>
                    {item.text}
                  </span>
                ))}
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container direction='column' alignItems='center' justifyContent='center'>
          <img className={classes.logoCorp} src={CorpLogo} alt='' />
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPageTeamAndADV;
