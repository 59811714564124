import bg from '../assets/images/documents/bg.webp';
import bgMobile from '../assets/images/documents/bgMobile.webp';
import header from '../assets/images/documents/header.webp';
import box1 from '../assets/images/documents/box1.webp';
import box2 from '../assets/images/documents/box2.webp';
import box3 from '../assets/images/documents/box3.webp';
import icon1 from '../assets/images/documents/icon1.webp';
import icon2 from '../assets/images/documents/icon2.webp';
import icon3 from '../assets/images/documents/icon3.webp';
import buttonHoverImage from '../assets/images/documents/hoverButton.webp';
import buttonImage from '../assets/images/documents/button.webp';

const headerImg = new Image();
headerImg.src = header;
const bgImg = new Image();
bgImg.src = bg;
const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const buttonImg = new Image();
buttonImg.src = buttonImage;
const buttonHoverImg = new Image();
buttonHoverImg.src = buttonHoverImage;
const box1Img = new Image();
box1Img.src = box1;
const box2Img = new Image();
box2Img.src = box2;
const box3Img = new Image();
box3Img.src = box3;
const icon1Img = new Image();
icon1Img.src = icon1;
const icon2Img = new Image();
icon2Img.src = icon2;
const icon3Img = new Image();
icon3Img.src = icon3;

export const sectionDocs = {
  pageInfo: {
    title: 'Documents',
    titleImg: headerImg.src,
    buttonImg: buttonImg.src,
    buttonHoverImg: buttonHoverImg.src,
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
  },
  cards: [
    // {
    //   name: 'pitchDeck',
    //   image: box1Img.src,
    //   icon: icon1Img.src,
    //   text: 'Take a look at our business plan!',
    //   url: '#',
    // },
    {
      name: 'whitepaper',
      image: box2Img.src,
      icon: icon2Img.src,
      text: 'Know more about Cats & Dogs!',
      url: 'https://cats-dogs.gitbook.io/whitepaper/',
    },
    {
      name: 'tokenomics',
      image: box3Img.src,
      icon: icon3Img.src,
      text: 'A professional economy design.',
      url: 'https://cats-and-dogs.gitbook.io/whitepaper/tokenomics/usdpet',
    },
  ],
};
