import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useRef, useState } from 'react';
//import ReactCardFlip from 'react-card-flip';
import { useIntersectionObserver } from '../../../../hooks/IntersectionObserver.hook';
import { sectionNfts } from '../../../../utils/sectionNftsData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'BloggerSans',
    color: '#68030F',
  },
  background: {
    margin: '0 auto',
    backgroundImage: `url(${sectionNfts.pageInfo.bg})`,
    backgroundSize: '100% 100%',
    height: '100vh',
    zIndex: -2,
  },
  contentText: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '15px',
    maxWidth: '70%',
    [theme.breakpoints.down('md')]: {
      lineHeight: '22px',
      fontSize: '18px',
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: '24px',
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      lineHeight: '28px',
      fontSize: '24px',
    },
  },
  contentText2: {
    fontFamily: 'BloggerSansBold',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '15px',
    maxWidth: '70%',
    lineHeight: '22px',
    fontSize: '18px',
  },
  bodyContainer: {
    maxWidth: '1440px',
    marginBottom: 'auto',
    marginTop: 'auto',
    padding: 0,
  },
  animation: {
    animation: 'fadeIn 1s ease-in-out',
  },
  animationNft: {
    animation: 'shineStar3 2s infinite ease-in-out',
  },
  cardsContainer: {
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '120px',
    [theme.breakpoints.only('sm')]: {
      width: '420px',
      bottom: '150px',
    },
    [theme.breakpoints.only('md')]: {
      width: '500px',
      bottom: '150px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '700px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '800px',
      bottom: '150px',
    },
  },
  textAnimation: {
    opacity: 0,
    animation: 'fadeIn 2s both 1',
    position: 'absolute',
    top: '90px',
    [theme.breakpoints.only('lg')]: {
      top: '80px',
    },
    [theme.breakpoints.only('xl')]: {
      top: '100px',
    },
  },
  headerImage: {
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '400px',
    },
  },
  soon: {
    margin: '0 auto',
    position: 'absolute',
    width: '50%',
    bottom: '100px',
    [theme.breakpoints.only('sm')]: {
      width: '300px',
      bottom: '140px',
    },
    [theme.breakpoints.only('md')]: {
      width: '400px',
      bottom: '130px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '550px',
      bottom: '90px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '32%',
      bottom: '110px',
    },
  },
  soon2: {
    margin: '0 auto',
    position: 'absolute',
    width: '350px',
    bottom: '60px',
  },
  animationSoon: {
    transitionDelay: '0s, 1000ms',
    animation: 'slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both',
  },
  mainContainer: {
    margin: '15% 15% 5%',
    width: '70%',
    height: '80%',
  },
  goldenContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: '100%',
  },
  golden: {
    width: '60%',
  },
  goldenText: {
    paddingTop: '20px',
    width: '60%',
  },
  nftsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '30%',
    height: '100%',
    [theme.breakpoints.only('sm')]: {
      height: '50%',
    },
    [theme.breakpoints.only('md')]: {
      height: '60%',
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
  },
  imageNfts: {
    width: '90%',
  },
  flip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  column10: {
    width: '10%',
  },
}));

const LandingNfts = () => {
  const classes = useStyles();
  const refSection = useRef(null);
  const isSectionVisible = useIntersectionObserver(refSection, { threshold: 0 }, true);
  const [golden, setGolden] = useState<string>('');
  const [currentCard, setCurrentCard] = useState<string>('');

  return (
    <div className={classes.root} id='nfts'>
      <Grid container direction='column' alignItems='center' justifyContent='center' className={classes.background}>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
          ref={refSection}
          className={`${classes.bodyContainer} ${isSectionVisible ? classes.textAnimation : ''}`}>
          {isSectionVisible && (
            <Grid container direction='column' alignItems='center' justifyContent='center'>
              <img src={sectionNfts.pageInfo.titleImg} alt='imageheader' className={classes.headerImage} />
            </Grid>
          )}
        </Grid>
        <Grid container direction='row' alignItems='center' justifyContent='center' className={classes.mainContainer}>
          <div className={classes.goldenContainer}>
            <img
              src={golden === 'show' ? sectionNfts.pageInfo.golden2 : sectionNfts.pageInfo.golden1}
              onPointerEnter={() => setGolden('show')}
              onPointerLeave={() => setGolden('')}
              alt='golden'
              className={classes.golden}
            />
            {isSectionVisible && (
              <img
                src={sectionNfts.pageInfo.goldenText}
                alt='exclusive vip'
                className={`${classes.goldenText} ${isSectionVisible ? classes.animationSoon : ''}`}
              />
            )}
          </div>
          <div className={classes.column10} />
          <div className={classes.nftsContainer}>
            {sectionNfts.nftsGroupList[0].cards.map((card, i) => (
              <div key={`${card.name} cardNfts`} className={classes.cardContainer}>
                <img
                  onPointerEnter={() => {
                    setCurrentCard(card.name);
                  }}
                  onPointerLeave={() => setCurrentCard('')}
                  aria-hidden='true'
                  src={card.image}
                  alt={card.name}
                  className={`${classes.imageNfts} ${currentCard === card.name ? classes.animationNft : ''}`}
                />
              </div>
            ))}
          </div>
          <div className={classes.column10} />
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingNfts;
