import header from '../assets/images/letsMove/title.webp';
import bg from '../assets/images/letsMove/bg.webp';
import bgMobile from '../assets/images/letsMove/bgMobile.webp';
import screen1 from '../assets/images/letsMove/screen1.webp';
import screen2 from '../assets/images/letsMove/screen2.webp';
import screen3 from '../assets/images/letsMove/screen3.webp';

const headerImg = new Image();
headerImg.src = header;

const bgImg = new Image();
bgImg.src = bg;
const bgMobileImg = new Image();
bgMobileImg.src = bgMobile;

const screen1Img = new Image();
screen1Img.src = screen1;
const screen2Img = new Image();
screen2Img.src = screen2;
const screen3Img = new Image();
screen3Img.src = screen3;

export const sectionletsMove = {
  pageInfo: {
    title: 'Let´s Move!',
    text: 'Adopt a Pet and start playing! Take care of them, gain Daily Income, engage in profitable mini-games, go for a walk with your pet and much more!',
    titleImg: headerImg.src,
    bg: bgImg.src,
    bgMobile: bgMobileImg.src,
  },
  cards: [
    {
      name: 'letsMove1',
      title: 'letsMove 1',
      text: `Lorem ipsum dolor sit amet`,
      image: screen1Img.src,
      backImage: screen1Img.src,
    },
    {
      name: 'letsMove2',
      title: 'letsMove 2',
      text: `Lorem ipsum dolor sit amet`,
      image: screen2Img.src,
      backImage: screen2Img.src,
    },
    {
      name: 'letsMove3',
      title: 'letsMove 3',
      text: `Lorem ipsum dolor sit amet`,
      image: screen3Img.src,
      backImage: screen3Img.src,
    },
  ],
};
